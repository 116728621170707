import React, {Fragment, useEffect, useState} from 'react';
import { Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {createOrUpdate} from "../../../../redux/actions/actions";
import {toast} from "react-toastify";
import {Submit} from "../../../../constant";
import {DatePicker} from "rsuite";
import {fetchZones} from "../../../../redux/actions/zones";
import {useDispatch} from "react-redux";
import moment from "moment";
import { addDays } from 'date-fns';

const AssessmentForm = (props) => {

    const formSchema = yup.object().shape({
        owner:yup.string().required('Please select owner'),
        site_type:yup.string().required('Please select site type'),
        regional_manager:yup.string().required('Please select regional manager'),
        // assessment_date:yup.string(),
        paser:yup.string().required('Please enter paser'),
        asphalt_area:yup.string().required('Please enter asphalt area'),
        concrete_area:yup.string().required('Please enter concrete area'),
        gravel_area:yup.string().required('Please enter gravel area'),
        curb:yup.string().required('Please enter curb'),
        crack_qty:yup.string().required('Please enter crack qty'),
        site_overview:yup.string().required('Please enter site overview'),
        status:yup.string().required('Please select status'),
    })
    const dispatch = useDispatch()
    const [assessment, setAssessment] = useState({});
    const [temp, setTemp] = useState(true)
    const [spinner, setSpinner] = useState(true);
    const { register, handleSubmit, errors } = useForm({mode: 'onChange', resolver: yupResolver(formSchema) });


    useEffect(() => {
        fetchAndSetStates()
    }, [])

    const fetchAndSetStates = () => {
        getAssessment()
    }

    const getAssessment = () => {
        setSpinner(false)
        dispatch(fetchZones('assessments/get', { 'id': props.id}))
            .then(({ data }) => {
                setSpinner(true)
                setAssessment(data.response[0])
            }).catch(err => {
            console.log(err)
        })
    }

    const handleDatePicker = (date) => {
       if(date){
           assessment['assessment_date']=moment(date).format('YYYY-MM-DD HH:mm:ss')
           setTemp(!temp)
       }else{
           assessment['assessment_date']=''
           setTemp(!temp)
       }
    }

    const handleSelect = (e) => {
        assessment[e.target.name] = e.target.value
        setTemp(!temp)
    }

    const onSubmit = (form) => {
        assessment.assessment_date = assessment.assessment_date ? moment(assessment.assessment_date).format('YYYY-MM-DD HH:mm:ss') : null
        setSpinner(false)
        assessment.id = parseInt(props.id)
        assessment.paser = form.paser ? form.paser : null
        assessment.assessment_date =  assessment.assessment_date != "" ? assessment.assessment_date : null
        assessment.asphalt_area = form.asphalt_area ?  form.asphalt_area : null
        assessment.concrete_area = form.concrete_area ?  form.concrete_area : null
        assessment.gravel_area = form.gravel_area ?  form.gravel_area : null
        assessment.curb = form.curb ?  form.curb : null
        assessment.crack_qty = form.crack_qty ?  form.crack_qty : null
        assessment.site_overview = form.site_overview
        createOrUpdate(assessment,'assessments').then(res => {
            if(res.status){
                toast.success("Record Successfully Updated !", {
                    position: toast.POSITION.TOP_CENTER,
                });
                getAssessment()
                setSpinner(true)
            }
        }).catch(error => {
            toast.error(error, {
                position: toast.POSITION.TOP_CENTER,
            });
        })
    }

    return (
        <Fragment>
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <label><b>Status:</b></label>
                    <Input className='form-control'
                           name='status'
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           value={assessment.status}
                           innerRef={register({ required: true })}
                           onChange={e => handleSelect(e)}
                    >
                        <option value=''>Please Select Status</option>
                        <option value={0}>Incomplete</option>
                        <option value={1}>Visited</option>
                        <option value={2}>Photos Added</option>
                        <option value={3}>Complete</option>
                        <option value={4}>Map Complete</option>
                    </Input>
                    <span className='text-danger'>{errors?.status?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Owner:</b></Label>
                    <Input className='form-control'
                           name='owner'
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           value={assessment.owner}
                           placeholder="Please select owner"
                           innerRef={register({ required: false })}
                           onChange={e => handleSelect(e)}
                    >
                        <option value=''>Please select owner</option>
                        <option value='CBRE - Pittsburgh'>CBRE - Pittsburgh</option>
                        <option value='landlord'>LandLord</option>
                    </Input>
                    <span className='text-danger'>{errors?.owner?.message}</span>
                </FormGroup>

                <FormGroup>
                    <label><b>Site Type:</b></label>
                    <Input className='form-control'
                           name='site_type'
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           value={assessment.site_type}
                           placeholder="Please select type"
                           innerRef={register({ required: false })}
                           onChange={e => handleSelect(e)}
                    >
                        <option value=''>Please select type</option>
                        <option value='Asphalt'>Asphalt</option>
                        <option value='Concrete'>Concrete</option>
                        <option value='Gravel'>Gravel</option>
                    </Input>
                    <span className='text-danger'>{errors?.site_type?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Assessment Date:</b></Label>
                    <div>
                        <DatePicker
                            appearance='subtle'
                            character='    -    '
                            format='MM/dd/yyyy'
                            onChange={handleDatePicker}
                            value={assessment.assessment_date? addDays(new Date(moment(assessment.assessment_date).format('YYYY-MM-DD')), 1): null}
                            placeholder="Select Date"
                            style={{ width: '100%',backgroundColor: '#f8f8f8', borderRadius: 8, border:'1px solid #ccc'}}
                        />
                    </div>

                    {/*<span className='text-danger'>{errors?.assessment_date?.message}</span>*/}
                </FormGroup>

                <FormGroup>
                    <Label><b>Paser:</b></Label>
                    <Input className="form-control"
                           name='paser'
                           type="text"
                           defaultValue={assessment.paser}
                           placeholder="Enter Paser"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.paser?.message}</span>
                </FormGroup>

                <FormGroup>
                    <label><b>Regional Manager:</b></label>
                    <Input className='form-control'
                           name='regional_manager'
                           value={assessment.regional_manager}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           innerRef={register({ required: false })}
                           onChange={e => handleSelect(e)}
                    >
                        <option value=''>Please Select Manager</option>
                        <option value={1}>Jeffrey Smith</option>
                    </Input>
                    <span className='text-danger'>{errors?.regional_manager?.message}</span>
                </FormGroup>


                <FormGroup>
                    <Label><b>Asphalt Area:</b></Label>
                    <Input className="form-control"
                           name="asphalt_area"
                           type="text"
                           defaultValue={assessment.asphalt_area}
                           placeholder="Enter Asphalt Area"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.asphalt_area?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Concrete Area:</b></Label>
                    <Input className="form-control"
                           name="concrete_area"
                           type="text"
                           defaultValue={assessment.concrete_area}
                           placeholder="Enter Concrete Area"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.concrete_area?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Gravel Area:</b></Label>
                    <Input className="form-control"
                           name="gravel_area"
                           type="text"
                           defaultValue={assessment.gravel_area}
                           placeholder="Enter Gravel Area"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.gravel_area?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Curb:</b></Label>
                    <Input className="form-control"
                           name="curb"
                           type="text"
                           defaultValue={assessment.curb}
                           placeholder="Enter Curb"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.curb?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Crack Qty:</b></Label>
                    <Input className="form-control"
                           name="crack_qty"
                           type="number"
                           defaultValue={assessment.crack_qty}
                           placeholder="Enter Crack Qty"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.crack_qty?.message}</span>
                </FormGroup>

                <FormGroup>
                    <Label><b>Site Overview:</b></Label>
                    <Input className="form-control"
                           name="site_overview"
                           type="textarea"
                           defaultValue={assessment.site_overview}
                           placeholder="Enter Site Overview"
                           innerRef={register({ required: false })}
                    />
                    <span className='text-danger'>{errors?.site_overview?.message}</span>
                </FormGroup>

                <Button type='submit' color="primary" className="mr-1 mb-3" disabled={!spinner}>
                    <Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}
                </Button>

            </Form>
        </Fragment>
    );
};

export default AssessmentForm;