import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import './form.css'

import { Submit, Cancel } from "../../../../constant";
import { createOrUpdate, fetchData } from '../../../../redux/actions/actions';

toast.configure();

const CampaignsForm = (props) => {

    const { fetchedRecord, setOpenDrawer } = props;

    const formSchema = yup.object().shape({
        campaign_name: yup.string().required('Please enter campaign name'),
        description: yup.string().required('Please enter description'),
        contact_id: yup.string().required('Please select contact'),
    })
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const [dropDownSelection, setDropDownSelection] = useState(1)
    const [spinner, setSpinner] = useState(true);
    const [accountNames, setAccountNames] = useState([]);
    const [contactId, setContactId] = useState('');


    useEffect(() => {
        setContactId(fetchedRecord?.contact_id)
        setDropDownSelection(fetchedRecord?.is_active)
    }, [fetchedRecord?.is_active])

    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.success("Record Successfully Updated!", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const onSubmit = (accountInfo) => {
        setSpinner(false)
        if (typeof fetchedRecord?.id !== "undefined") {
            accountInfo['id'] = fetchedRecord.id;
        }
        accountInfo['is_active'] = dropDownSelection;
        console.log(accountInfo, 'formFields');
        createOrUpdate(accountInfo, 'campaigns').then(res => {
            directiontoaster('directionssuccessToast')
            console.log(res, 'createOrUpdate');
            setSpinner(true)
            props.setOpenDrawer(false)
            props.getCampaigns()

        })
    }

    useEffect(() => {
        fetchData('contacts/list')('')
            .then(res => {
                console.log(res.data.response);
                setAccountNames(res.data.response)
            })

    }, [])


    return (

        <Fragment>

            <Form style={{ marginTop: '4px' }} className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <label><b>Contact Name:</b></label>
                    <Input className='form-control'
                           innerRef={register({ required: true })}
                           name={'contact_id'}
                           value={contactId}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setContactId(parseInt(e.target.value))} >
                        <option value={''} >Select Contact</option>
                        {
                            accountNames.map((item, index) => {
                                return <option key={index} value={item.id} >{item.first_name}</option>
                            })
                        }

                    </Input>
                    <span className='text-danger'>{errors?.contact_id?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Campaign Name:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.campaign_name} name="campaign_name" type="text" placeholder="Campaign Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.campaign_name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Description:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.description} name="description" type="textarea" placeholder="Description" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.description?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Status:</b></label>
                    <Input className='form-control'
                           name={'is_active'}
                           value={dropDownSelection}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setDropDownSelection(parseInt(e.target.value))} >

                        <option value={0}>Inactive</option>
                        <option value={1}>Active</option>

                    </Input>
                    <span className='text-danger'>{errors?.status?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" disabled={!spinner}><Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}</Button>
                    <Button onClick={() => setOpenDrawer(false)} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form >

        </Fragment >
    );
};

export default CampaignsForm;