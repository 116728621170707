import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap'
import { Submit, Cancel } from "../../../../constant";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { createOrUpdate } from '../../../../redux/actions/actions';
import "react-toastify/dist/ReactToastify.css";
import './form.css'
import { useDispatch, useSelector } from 'react-redux';
import { setOpenDrawer } from '../../../../redux/actions/common'

toast.configure();

const AccountsForm = (props) => {

    const formSchema = yup.object().shape({
        name: yup.string().required('Please enter name').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        address: yup.string().required('Please enter address'),
        city: yup.string().required('Please enter city').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        state: yup.string().required('Please enter state').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        zip: yup.string().required('Please enter zip code').min(5).max(5),
    })
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const dispatch = useDispatch();
    const { fetchedRecord } = useSelector(state => state.Common)
    const [dropDownSelection, setDropDownSelection] = useState(1)
    const [spinner, setSpinner] = useState(true);

    useEffect(() => {
        setDropDownSelection(fetchedRecord.is_active)
    }, [fetchedRecord.is_active])

    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.success("Record Successfully Updated!", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const onSubmit = (accountInfo) => {
        setSpinner(false)
        if (typeof fetchedRecord?.id !== "undefined") {
            accountInfo['id'] = fetchedRecord.id;
        }
        accountInfo['is_active'] = dropDownSelection;
        createOrUpdate(accountInfo, 'accounts').then(res => {
            directiontoaster('directionssuccessToast')
            setSpinner(true)
            dispatch(setOpenDrawer(false))
            props.getUsers(1)
        })
    }

    return (

        <Fragment>

            <Form style={{ marginTop: '4px' }} className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <label><b>Account Name:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.name} name="name" type="text" placeholder="Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Address:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.address} name="address" type="text" placeholder="Address" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.address?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>City:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.city} name="city" type="text" placeholder="City" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.city?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>State:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.state} name="state" type="text" placeholder="State" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.state?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Zip Code:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.zip} name='zip' type="number" placeholder="Zip Code" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.zip?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Status:</b></label>
                    <Input className='form-control'
                           name={'is_active'}
                           value={dropDownSelection}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setDropDownSelection(parseInt(e.target.value))} >

                        <option value={0}>Inactive</option>
                        <option value={1}>Active</option>

                    </Input>
                    <span className='text-danger'>{errors?.status?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" disabled={!spinner}>
                        <Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}
                    </Button>
                    <Button onClick={() => dispatch(setOpenDrawer(false))} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form>

        </Fragment >
    );
};

export default AccountsForm;