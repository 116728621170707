import React, {Fragment, useEffect, useState} from "react";
import { GoogleMap, useJsApiLoader, DrawingManager, Polygon, Polyline, Marker, InfoBox, InfoWindow } from '@react-google-maps/api';
import { getAreaOfPolygon, getCenterOfBounds } from 'geolib';
import {Card, CardBody, Container, Row, Col, Nav, NavItem, NavLink} from "reactstrap";
import { CheckCircle, Map, HelpCircle, Image, File, Paperclip, Settings, Edit, Trash  } from 'react-feather';
import { fetchZones } from "../../../../redux/actions/zones";
import { useDispatch } from "react-redux";
import { Drawer } from "rsuite";
import ZoneForm from "../sidebar/zoneForm";
import Loader from "../../../../components/spinner/spinner"
import { deleteById } from "../../../../redux/actions/actions";
import SweetAlert from "sweetalert2";
import { toast } from "react-toastify";
import {useLocation,useParams} from "react-router-dom";
import './form.css'
import {themeConfig} from "../../../../configs/themeConfig";
import Breadcrumb from "../../../../layout/breadcrumb";
import Rating from 'react-rating'
import AssessmentForm from "../sidebar/assessmentForm";

const containerStyle = {width: 'auto', height: 600};
export const isArrEmpty = arr => arr.length=== 0

const Maps = () => {
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [showInfo, setShowInfo] = useState(false);
    const [info, setInfo] = useState({})
    const [openDrawer, setOpenDrawer] = useState(false)
    const [savedZones, setSavedZones] = useState([])
    const [spinner, setSpinner] = useState(true);
    const [currentShape, setCurrentShape] = useState(false);
    const [searchBox, setSearchBox] = useState(null);
    const [zone, setZone] = useState({})
    const [activeTab, setActiveTab] = useState({})
    const [rating, setRating] = useState(0)
    const [assessment,setAssessment] = useState({})
    const { id } = useParams()


    useEffect(() => {
        fetchAndSetStates()
    }, [])

    const fetchAndSetStates = () => {
        getZones()
        getAssessment()
    }

    const getZones = () => {
        setLoading(true)
        dispatch(fetchZones('assessment/zones', { 'id': id}))
            .then(({ data }) => {
                setLoading(false)
                setSavedZones(data.response)
            }).catch(err => {
                console.log(err)
            })
    }

    const getAssessment = () => {
        setLoading(true)
        dispatch(fetchZones('assessments/get', { 'id': id}))
            .then(({ data }) => {
                setLoading(false)
                setAssessment(data.response[0])
            }).catch(err => {
            console.log(err)
        })
    }

    const getMapCenter = () => {
        if(zone.zone_center && !isArrEmpty(zone.zone_center)){
            return zone.zone_center
        }
        if(!isArrEmpty(savedZones)){
            let c = JSON.parse(savedZones[savedZones.length-1].zone_center);
            return { lat: c.latitude, lng: c.longitude }
        }else{
            return { lat: 41.85003, lng: -87.65005 }
        }
    }

    const handleDrawer = (isOpen) => {
        setOpenDrawer(isOpen)
    }

    const { isLoaded } = useJsApiLoader({
        libraries: ['drawing','places'],
        id: 'google-map-script',
        googleMapsApiKey: themeConfig.app.apiKey
    })

    const onPolygonComplete = (polygon) => {
        let polygonBounds = polygon.getPath();
        let bounds = [];
        for (let i = 0; i < polygonBounds.length; i++) {
            let point = {
                lat: polygonBounds.getAt(i).lat(),
                lng: polygonBounds.getAt(i).lng()
            };
            bounds.push(point);
        }
        zone.shape = "polygon"
        zone.zone_coordinates = JSON.stringify(bounds)
        zone.zone_area = polygonArea(bounds)
        zone.zone_center = JSON.stringify(getCenterOfBounds(bounds));
        zone.assessment_id= id
        zone.area_unit= "m"
        zone.zone_color_fill= "#cccccc"
        zone.zone_color_stroke= "#000000"
        zone.stroke_width= "2"
        zone.show_label= "1"
        setCurrentShape(polygon)
        setZone(zone)
        setOpenDrawer(true)
    }

    const polygonArea = (bounds) => {
        let sides = []
        bounds.map((side, i) => {
            sides[i] = [side.lat, side.lng]
        })
        return getAreaOfPolygon(sides)
    }

    const onPolylineComplete = line => {
        let lineBounds = line.getPath();
        let bounds = [];
        for (let i = 0; i < lineBounds.length; i++) {
            let point = {
                lat: lineBounds.getAt(i).lat(),
                lng: lineBounds.getAt(i).lng()
            };
            bounds.push(point);
        }
        zone.shape = "polyline"
        zone.zone_coordinates = JSON.stringify(bounds)
        zone.zone_center = JSON.stringify(getCenterOfBounds(bounds))
        zone.assessment_id = id
        zone.area_unit= "m"
        zone.zone_color_fill= "#cccccc"
        zone.zone_color_stroke= "#000000"
        zone.stroke_width= "2"
        zone.show_label= "1"
        setCurrentShape(line)
        setZone(zone)
        setOpenDrawer(true)
    }

    const onMarkerComplete = mark => {
        let markerCords = mark.getPosition();
        let bounds = {}
        bounds.lng = markerCords.lng()
        bounds.lat = markerCords.lat()
        zone.shape = "marker"
        zone.zone_coordinates = JSON.stringify([bounds])
        zone.zone_center = JSON.stringify({'latitude': markerCords.lat(),'longitude': markerCords.lng()})
        zone.assessment_id = id
        zone.area_unit= "m"
        zone.zone_color_fill= "#cccccc"
        zone.zone_color_stroke= "#000000"
        zone.stroke_width= "2"
        zone.show_label= "1"
        setCurrentShape(mark)
        setZone(zone)
        setOpenDrawer(true)
    }

    const shortLatLng = coordinates => coordinates.map(c => { return { lat: c.lat, lng: c.lng } })

    const renderShapes = zone => {
        let { id, shape, zone_coordinates, zone_color_fill, zone_color_stroke, stroke_width, show_label } = zone

        const getOptions = (shape) => {
            const width = shape == "polyline" ? 6 : 3;
            return {
                fillColor: zone_color_fill || "lightblue",
                fillOpacity: 0.2,
                strokeColor: zone_color_stroke || "red",
                strokeOpacity: 1,
                strokeWeight: stroke_width * width || 2,
                clickable: false,
                draggable: false,
                editable: false,
                geodesic: false,
                zIndex: 1
            }
        }
        let _coordinates = shortLatLng(JSON.parse(zone_coordinates));
        const paths = [_coordinates];

        if (shape === "polygon") {
            return (
                <div key={id}>
                    <Polygon
                        paths={paths}
                        options={getOptions(shape)}>
                    </Polygon>
                    {show_label && infoBox(zone)}
                </div>
            )
        }

        if (shape === "polyline") {
            return (
                <div key={id} >
                    <Polyline
                        path={_coordinates}
                        options={getOptions(shape)}>
                    </Polyline>
                    {show_label && infoBox(zone)}
                </div>
            )
        }
        if(shape==="marker") {
            return (
                <div key={id}>
                    <Marker position={_coordinates[0]}>
                        {show_label && infoBox(zone)}
                    </Marker>
                </div>
            )
        }
    }

    const openInfo = (zone) => {
        setInfo(zone);
        setShowInfo(true);
    }

    const editZone = (info) => {
        setZone(info)
        setOpenDrawer(true)
    }

    const showAlert = (id) => {
        SweetAlert.fire({
            title: "Are you sure you want to do this?",
            cancelButtonText: "Cancel",
            confirmButtonText: "Confirm",
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonColor: '#fbbb1a',
            confirmButtonColor: "black",
        }).then(result => {
            if (result.isConfirmed === true) {
                deleteById({ id }, 'zones')
                    .then(res => {
                    }).finally(() => {
                    toast.success("Record Successfully Deleted!", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    getZones()
                    setShowInfo(false);
                })
            }
        })
    }

    const deleteZone = (id) => {
        if(id){
            showAlert(id)
        }else{
            // Destroys the shape that has been drawn by the manager.
            currentShape.setMap(null);
            setShowInfo(false);
        }
    }

    const infoBox = (zone, purpose = 'label') => {
        let { zone_center, zone_title } = zone;
        zone_center = JSON.parse(zone_center);
        return <InfoBox
            position={{ lat: zone_center.latitude, lng: zone_center.longitude }}
            options={{
                pixelOffset: new window.google.maps.Size(-30, -70),
                enableEventPropagation: true,
                boxStyle: {
                    padding: '2px'
                },
                closeBoxURL: ``,
            }} >
            <div className='d-flex flex-column justify-content-center'>
                <div className='bordered-dark bg-light' style={{ padding: '4px', border: '1px solid black', color: 'black' }}>
                    <a onClick={() => openInfo(zone)}>{zone_title}</a>
                </div>
            </div>
        </InfoBox>;
    }

    const renderInfoWindow = () => {
        let { zone_center } = info;
        zone_center = JSON.parse(zone_center);
        return <InfoWindow
            position={{ lat: zone_center.latitude, lng: zone_center.longitude }}
            zIndex={1}
            onCloseClick={() => setShowInfo(false)}
        >
            <div>
                <div className='d-flex flex-row justify-content-space-between align-items-center'>
                    <h5 className="mb-0 mr-2">{info.zone_title}</h5>
                    <div>
                        <Edit size={20} style={{ marginRight: 8 }} onClick={() => editZone(info)} />
                        <Trash size={20} style={{ marginRight: 8 }} onClick={() => deleteZone(info.id)} />
                    </div>
                </div>
                <hr className="mb-1 mt-0" />
                <div className='d-flex flex-column'>
                    <h6 className="mb-0">Description</h6>
                    <p className="mb-0">{info.description ? info.description : 'N/A'}</p>
                    <h6 className="mb-0">Area</h6>
                    <p className="mb-0">{info.zone_area} {info.area_unit}</p>
                    <h6 className="mb-0">Paser Rating</h6>
                    <Rating
                        style={{color:'#ffa800'}}
                        initialRating={assessment.paser ? assessment.paser : 0}
                        emptySymbol="fa fa-star-o"
                        fullSymbol="fa fa-star "
                        // onChange={(rate) => setRating(assessment.paser ? assessment.paser : null)}
                    >
                    </Rating>

                </div>
            </div>
        </InfoWindow>;
    }

    if(loading){
        return <Loader />
    }else{
        return isLoaded ? (
            <>
                <Drawer className='zoneDrawer' open={openDrawer} onClose={() => handleDrawer(false)} size={'sm'} style={{ width: '25%' }}>
                    <Drawer.Header>
                        <Drawer.Title>Area Details</Drawer.Title>
                    </Drawer.Header>
                    <Drawer.Body style={{ padding: '30px 40px' }}>
                        <ZoneForm setOpenDrawer={setOpenDrawer} zone={zone} setZone={setZone} deleteZone={deleteZone} getZone={getZones} />
                    </Drawer.Body>
                </Drawer>
                <Fragment>
                    <Breadcrumb parent="Campaigns" title={<h3 style={{ marginLeft: '20px' }} >Assessment</h3>} />

                    <Col md="12" className="project-list">
                        <Card>
                            <Row>
                                <Col sm="12">
                                    <Nav tabs style={{ marginLeft: '8pc' }}>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "1" ? "active" : ''} onClick={() => setActiveTab("1")}><Map />Map</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "2" ? "active" : ''} onClick={() => setActiveTab("2")}><Image />Gallery</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><HelpCircle />ADA</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><CheckCircle />Recommendation</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><File />Plans</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><Paperclip />Attachment</NavLink></NavItem>
                                        <NavItem><NavLink style={{ color:'black' }} className={activeTab === "3" ? "active" : ''} onClick={() => setActiveTab("3")}><Settings />Settings</NavLink></NavItem>
                                    </Nav>
                                </Col>
                            </Row>
                        </Card>
                    </Col>


                    <Container fluid={true}>
                        <Row>
                            <Col sm="12">
                                <Card>
                                    <CardBody>

                                        <Row>
                                            <div className="col">
                                                <AssessmentForm id={id}/>
                                            </div>

                                            <div className="col-9">

                                                <GoogleMap
                                                    center={getMapCenter()}
                                                    id="drawing-manager-example"
                                                    mapContainerStyle={containerStyle}
                                                    zoom={18}
                                                    mapTypeId="satellite"
                                                >
                                                    {savedZones && savedZones.map(el => renderShapes(el))}
                                                    {showInfo && renderInfoWindow()}

                                                    <DrawingManager
                                                        onPolygonComplete={onPolygonComplete}
                                                        onPolylineComplete={onPolylineComplete}
                                                        onMarkerComplete={onMarkerComplete}
                                                        options={{
                                                            drawingControl: true,
                                                            drawingControlOptions: {
                                                                style: window.google.maps.MapTypeControlStyle.HORIZONTAL_BAR,
                                                                position: window.google.maps.ControlPosition.TOP_CENTER,
                                                                drawingModes: [
                                                                    window.google.maps.drawing.OverlayType.POLYGON,
                                                                    window.google.maps.drawing.OverlayType.POLYLINE,
                                                                    window.google.maps.drawing.OverlayType.MARKER
                                                                ]
                                                            },
                                                            polygonOptions: {
                                                                fillColor: "#cccccc",
                                                                fillOpacity: 0.2,
                                                                strokeWeight: 2,
                                                                strokeColor: "#000000",
                                                                clickable: true,
                                                                editable: false,
                                                                geodesic: false,
                                                                visible: true,
                                                                zIndex: 1
                                                            }
                                                        }}
                                                    />
                                                </GoogleMap>
                                            </div>
                                        </Row>

                                    </CardBody>
                                </Card>
                            </Col>

                        </Row>

                    </Container>
                </Fragment>
            </>

        ) : <Loader />
    }

}

export default Maps;