import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap'
import { Submit, Cancel } from "../../../../constant";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { createOrUpdate, fetchData } from '../../../../redux/actions/actions';
import { toast } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { setFetchedRecord, setOpenDrawer } from '../../../../redux/actions/common'
import { useSelector, useDispatch } from 'react-redux';


toast.configure();

const ContactsForm = (props) => {

    const { fetchedRecord } = useSelector(state => state.Common)
    const dispatch = useDispatch()

    const formSchema = yup.object().shape({
        first_name: yup.string().required('Please enter first name').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        last_name: yup.string().required('Please enter last name').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        email: yup.string().email().required('Please enter email'),
        phone: yup.string().required('Please enter phone number').min(11).max(11),
        account_id: yup.string().required('Please select account')
    })

    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });
    const [dropDownSelection, setDropDownSelection] = useState(1)
    const [spinner, setSpinner] = useState(true);
    const [accountNames, setAccountNames] = useState([]);
    const [accountId, setAccountId] = useState('');

    useEffect(() => {
        setAccountId(fetchedRecord.account_id)
        setDropDownSelection(fetchedRecord.is_active)

    }, [fetchedRecord])

    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.success("Record Successfully Updated!", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const onSubmit = (accountInfo) => {
        setSpinner(false)
        if (typeof fetchedRecord?.id !== "undefined") {
            accountInfo['id'] = fetchedRecord.id;
        }
        accountInfo['is_active'] = dropDownSelection;
        createOrUpdate(accountInfo, 'contacts').then(res => {
            directiontoaster('directionssuccessToast')
            setSpinner(true)
            dispatch(setOpenDrawer(false))
            props.getUsers(1)
        })
    }

    useEffect(() => {
        fetchData('accounts/list')('')
            .then(res => {
                setAccountNames(res.data.response)
            })
    }, [])


    return (

        <Fragment>

            <Form style={{ marginTop: '4px' }} className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <label><b>Account Name:</b></label>
                    <Input className='form-control'
                           innerRef={register({ required: true })}
                           name={'account_id'}
                           value={accountId}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setAccountId(parseInt(e.target.value))} >
                        <option value={''} >Select Accounts</option>
                        {
                            accountNames.map((item, index) => {
                                return <option key={index} value={item.id} >{item.name}</option>
                            })
                        }
                    </Input>
                    <span className='text-danger'>{errors?.account_id?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>First Name:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.first_name} name="first_name" type="text" placeholder="First Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.first_name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Last Name:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.last_name} name="last_name" type="text" placeholder="Last Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.last_name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Email:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.email} name="email" type="email" placeholder="Email" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.email?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Phone:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.phone} name='phone' type="text" placeholder="Phone" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.phone?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Status:</b></label>
                    <Input className='form-control'
                           name={'is_active'}
                           value={dropDownSelection}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setDropDownSelection(parseInt(e.target.value))} >
                        <option value={0}>Inactive</option>
                        <option value={1}>Active</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" disabled={!spinner}><Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}</Button>
                    <Button onClick={() => dispatch(setOpenDrawer(false))} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form>

        </Fragment >
    );
};

export default ContactsForm;