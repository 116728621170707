import { Home, Server, Map, Zap, Disc, Settings, FileText, Users, AlignJustify ,  Flag} from 'react-feather'
export const MENUITEMS = [
    // {
    //     menutitle: "Main Menu",
    //     menucontent: "Analytics & Filters",
    //     Items: [
    //         {
    //             title: 'Dashboard', icon: Home, type: 'link', path: `${process.env.PUBLIC_URL}/dashboard`,

    //         }
    //     ]
    // },
    {
        menutitle: "CRM",
        menucontent: "Manage accounts & contacts",
        Items: [
            // {
            //     title: 'Dashboard', icon: Home, type: 'link', path: `${process.env.PUBLIC_URL}/dashboard`,
            // },
            {
                title: 'Accounts', icon: Server, type: 'link', path: `${process.env.PUBLIC_URL}/accounts`,
            },
            {
                title: 'Contacts', icon: Users, type: 'link', path: `${process.env.PUBLIC_URL}/contacts`,
            },
            {
                title: 'Campaigns', icon: Flag, type: 'link', path: `${process.env.PUBLIC_URL}/campaigns`,

            },

        ]
    },

]