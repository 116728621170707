import React, { Fragment } from 'react';
import { Container, Row, Col, Card, CardBody, CardHeader } from 'reactstrap'

import { LoaderStyle } from '../../constant';

import './spinner.css'

const Spinner = () => {

    return (
        <Fragment>
            {/* <Breadcrumb parent="Ui Kits" title="Spinners" /> */}
            {/*<Container fluid={true}>*/}
            {/*    <Row>*/}
            {/*        <Col sn="12">*/}
            {/*            <Card>*/}
                            {/* <CardHeader>
                                <h5>{LoaderStyle}</h5>
                            </CardHeader> */}
                            {/*<CardBody className="row" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>*/}
                            {/*    <Col>*/}
                                    {/* <h6 className="sub-title mb-0 text-center">{"Loader 8"}</h6> */}
                                    <div className="loader-box" >
                                        <div className="loader-8"></div>
                                    </div>
                                {/*</Col>*/}
                            {/*</CardBody>*/}
            {/*            </Card>*/}
            {/*        </Col>*/}
            {/*    </Row>*/}
            {/*</Container>*/}
        </Fragment >
    )
}

export default Spinner;