import React, { useState } from 'react';
import { Container, Row, Col, Form, FormGroup, Input, Label, Button, TabContent, TabPane } from 'reactstrap'
import { Password, SignIn, EmailAddress, RememberPassword, ForgotPassword, CreateAccount, FIREBASE, AUTH0, JWT, LoginWithJWT } from '../../constant';
import { useDispatch } from 'react-redux';
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'

import "react-toastify/dist/ReactToastify.css";

import useJwt from '../../auth/useJwt';
import { handleLogin } from '../../redux/actions/auth';
import {loading} from "../../redux/actions/common";

const Signin = (props) => {

    const dispatch = useDispatch()
    const [togglePassword, setTogglePassword] = useState(false)
    const [selected, setSelected] = useState("firebase");

    const formSchema = yup.object().shape({
        email: yup.string().email().required(),
        password: yup.string().required(),
    })
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });


    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.error("Wrong Credentials !", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const HideShowPassword = (tPassword) => {
        setTogglePassword(!tPassword)
    }

    const onSubmit = (credentials) => {
        useJwt.login(credentials)
            .then(data => {
                dispatch(handleLogin(data))
                dispatch(loading(true))
            }).catch(error => {
                directiontoaster('directionssuccessToast')
            })

        return false;
    }

    return (
        <Container fluid={true} className="p-0">
            <Row className="m-0">
                <Col xs="12" className="p-0">
                    <div className="login-card">
                        <div>
                            <div>
                                <a className="logo" href="#javascript">
                                    <img style={{maxWidth: '20%'}} className="img-fluid for-light" src={require("../../assets/images/logo/logo.png")} alt="" />
                                    <img style={{maxWidth: '20%'}} className="img-fluid for-dark" src={require("../../assets/images/logo/logo.png")} alt="" />
                                </a>
                            </div>
                            <div className="login-main login-tab">

                                <TabContent activeTab={selected} className="content-login">
                                    <TabPane className="fade show" tabId={selected === "firebase" ? "firebase" : "jwt"}>
                                        <Form className="theme-form" onSubmit={handleSubmit(onSubmit)} >
                                            <h4>Sign In</h4>
                                            <p>{"Enter your email & password to login"}</p>
                                            <FormGroup>
                                                <Label className="col-form-label">{EmailAddress}</Label>
                                                <Input className="form-control" name='email' type="email" required="" placeholder="Test@gmail.com" innerRef={register({ required: true })} />
                                                <span className='text-danger'>{errors?.email?.message}</span>

                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="col-form-label">{Password}</Label>
                                                <Input className="form-control" name='password' type={togglePassword ? "text" : "password"} innerRef={register({ required: true })} required="" placeholder="*********" />
                                                <div className="show-hide" onClick={() => HideShowPassword(togglePassword)}><span className={togglePassword ? "" : "show"}></span></div>
                                                <span className='text-danger'>{errors?.password?.message}</span>

                                            </FormGroup>
                                            <div className="form-group mb-0">
                                                <div className="checkbox ml-3">
                                                    <Input id="checkbox1" type="checkbox" />
                                                    <Label className="text-muted" for="checkbox1">{RememberPassword}</Label>
                                                </div>
                                                <a className="link" href="#javascript">{ForgotPassword}</a>
                                                {selected === "firebase" ?
                                                    <Button type='submit' color="secondary" className="btn-block" >{SignIn}</Button>
                                                    :
                                                    <Button color="primary" className="btn-block">{LoginWithJWT}</Button>
                                                }
                                            </div>
                                            <p className="mt-4 mb-0">{"Don't have account?"}<a className="ml-2" href="#javascript">{CreateAccount}</a></p>
                                        </Form>
                                    </TabPane>
                                    <TabPane className="fade show" tabId="auth0">
                                        <div className="auth-content">
                                            <img src={require("../../assets/images/auth-img.svg")} alt="" />
                                            <h4>{"Welcome to login with Auth0"}</h4>
                                            <p>{"Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy"}</p>
                                            <Button color="info">{AUTH0}</Button>
                                        </div>
                                    </TabPane>
                                </TabContent>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </Container>
    );
}

export default Signin;