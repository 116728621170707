import axios from 'axios';

export default axios.create({
    // baseURL: `http://localhost/apilcldev-Merged/v1/`,
    // baseURL:`http://localhost/lcl/v1/`,
    baseURL: `https://devapi.livechatlog.com/api/v1/`,
    // baseURL:`https://v2.livechatlog.com/api/v1/`,
    // baseURL:`https://staging.livechatlog.com/v2-livechatlog/v1/`,
    // baseURL:`https://staging.livechatlog.com/api-livechatlog/v1/`, // For Merging Purpose
    // baseURL: `https://staging.livechatlog.com/widgets/v1/`,
    headers: {
        'Cache-Control': 'no-cache',
        'Pragma': 'no-cache',
        'Expires': '0',
    }
});