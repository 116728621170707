import React, { Fragment, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import DataTable from 'react-data-table-component';
import { Container, Row, Col, Card } from 'reactstrap';
import { Edit, Trash2 } from 'react-feather';
import { toast } from 'react-toastify'
import SweetAlert from 'sweetalert2'
import { fetchDBTable, deleteById, fetchById } from '../../redux/actions/actions';
import Breadcrumb from '../../layout/breadcrumb';
import Spinner from '../spinner/spinner';
import Header from '../header/header';
import PaginationComponent from '../pagination/Pagination';
import './dataTable.css'
import { setContacts } from "../../redux/actions/contacts";
import { setFetchedRecord, setOpenDrawer, setFilters } from '../../redux/actions/common';
import { useHistory, Link } from 'react-router-dom';

const DataTableComponent = (props) => {
    const [loading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [totalNoOfResults, setTotalNoOfResults] = useState(0)

    const dispatch = useDispatch()
    const common = useSelector((state) => state.Common)
    const contacts = useSelector((state) => state.Contact)
    const history = useHistory();

    useEffect(() => {
        fetchAndSetStates()
    }, [])


    const fetchAndSetStates = () => {
        getUsers()
    }

    const getUsers = (pageNum) => {
        setLoading(true)
        dispatch(setFilters({ ...common.filters, page: pageNum ? pageNum : 1 }))
        dispatch(fetchDBTable({ ...common.filters, page: pageNum ? pageNum : 1 }, props.api))
            .then(({ data }) => {
                dispatch(setContacts(data.response))
            }).catch(err => {
        }).finally(() => {
            setLoading(false)
        })
    }

    const setTotalResults = (results) => {
        setTotalNoOfResults(results)
    }

    const requestRowsChange = (rows) => {
        setRowsPerPage(rows)
    }

    const showAlert = (id) => {
        SweetAlert.fire({
            title: "Are you sure you want to delete this?",
            cancelButtonText: "Cancel",
            confirmButtonText: "Confirm",
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonColor: '#fbbb1a',
            confirmButtonColor: "black",
        }).then(result => {
            if (result.isConfirmed === true) {
                deleteById({ id }, props.deleteApi)
                    .then(res => {
                    }).finally(() => {
                    directiontoaster('directionssuccessToast')
                    getUsers()
                })
            }
        })
    }

    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.success("Record Successfully Deleted!", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const editAction = (id) => {
        fetchById({ id }, props.fetchApi)
            .then(res => {
                dispatch(setFetchedRecord({ ...res.data.response[0], site_id: id }))
                dispatch(setOpenDrawer(true))
            })
    }

    const deleteAction = (id) => {
        showAlert(id);
    }

    const actionBtns = (id, site_id, assessment_id) => {
        return (
            <div style={{ cursor: 'pointer', }} >
                {props?.additionalBtn &&
                <Link to={`${process.env.PUBLIC_URL}/assessments/${assessment_id}`} style={{ marginRight: '10px', cursor: 'pointer', color: '#000' }}>
                    <props.additionalBtn size={14} />
                </Link>}
                <Edit onClick={() => editAction(id, site_id)} size={14} />
                <Trash2 onClick={() => deleteAction(id)} style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }} size={15} />
                {/*<ExternalLink style={{ cursor: 'pointer' }} size={15} />*/}
            </div>
        )
    }

    return (
        <Fragment>
            <Breadcrumb title={<h3 style={{ marginLeft: '20px' }} >{props?.screenTitle}</h3>} />

            {props.drawerComp && <props.drawerComp getUsers={getUsers} />}

            <Container fluid={true}>
                <Row>
                    <Col sm="12 ">
                        <Header
                            getUsers={getUsers}
                            setIsEdit={props.setIsEdit}
                            accountsName={props.accountsName}
                            dropDown={props.dropDown}
                            setOpenDrawer={props.setOpenDrawer}
                            searchFilterName={props.searchFilterName}
                            setTotalResults={setTotalResults}
                            api={props.api}
                            rowsPerPage={rowsPerPage}
                            requestRowsChange={requestRowsChange}
                            options={options}
                            handleLoading={loading}
                            setLoading={setLoading}
                        />

                        <Card style={{ borderRadius: 0 }}>
                            <DataTable
                                customStyles={{
                                    table: { style: { borderColor: 'transparent' } },
                                    headRow: { style: { backgroundColor: '#f8f8f8', borderColor: 'transparent' } },
                                }}
                                data={contacts.contactList.rows}
                                searchText={props.searchText}
                                columns={[...props.tableColumnsName, { name: 'Action', selector: row => actionBtns(row.id, row.site_id, row.assessment_id) }]}
                                progressPending={loading}
                                progressComponent={<Spinner />}
                                noHeader={true}
                                loading={loading}
                            />

                            {props.drawerComp && <PaginationComponent
                                getUsers={getUsers}
                                totalNoOfResults={contacts.contactList.total}
                                rowsPerPage={common.filters.perPage}
                            />}
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    )

}

export default DataTableComponent