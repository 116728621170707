import { LOGIN, LOGOUT } from './actionTypes'

const initialState = {
    userData: {},
}

const authReducer = (state = initialState, action) => {
    if (action.type === LOGIN) {
        return { ...state, userData: action.data }
    }
    else if (action.type === LOGOUT) {
        return { ...initialState };
    }
    return { ...state };
}


export default authReducer;