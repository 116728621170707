import React, { Fragment, useState, useEffect } from 'react';
import DataTable from '../../table';
import { Drawer, Placeholder } from 'rsuite';
import "react-toastify/dist/ReactToastify.css";
import './index.css'
import AccountsForm from '../sidebar/form'
import { setFetchedRecord, setFilters, setOpenDrawer } from '../../../../redux/actions/common';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { themeConfig } from '../../../../configs/themeConfig';


const Accounts = () => {
    const common = useSelector(state => state.Common)
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setFilters({ endDate: "", keyword: "", page: 1, perPage: 10, startDate: "", id: null }))
    }, [])

    const handleClose = () => {
        dispatch(setOpenDrawer(false))
        dispatch(setFetchedRecord({}))
    }

    const active = () => {
        return (
            <div style={{ backgroundColor: '#38b000', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Active</span>
            </div>
        )
    }
    const inactive = () => {
        return (
            <div style={{ backgroundColor: '#e63946', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Inactive</span>
            </div>
        )
    }

    const drawerComp = props => {
        return (
            <Drawer open={common.openDrawer} onClose={handleClose} size={'sm'} style={{ width: '25%' }}>
                <Drawer.Header >
                    <Drawer.Title>  {Object.keys(common.fetchedRecord).length > 0 ? 'Edit Account' : 'Create Account'}</Drawer.Title>
                </Drawer.Header>

                <Drawer.Body >
                    <AccountsForm getUsers={props.getUsers} fetchedRecord={common.fetchedRecord} />
                </Drawer.Body>
            </Drawer>
        )
    }

    return (
        <Fragment>
            <DataTable
                api={'accounts'}
                fetchApi={'accounts/get'}
                deleteApi={'accounts'}
                drawerComp={drawerComp}
                setOpenDrawer={setOpenDrawer}
                screenTitle={'Accounts'}
                searchFilterName={'name'}
                tableColumnsName={[
                    { name: 'Name', selector: 'name' },
                    { name: 'Address', selector: 'address' },
                    { name: 'City', selector: 'city' },
                    { name: 'State', selector: 'state' },
                    { name: 'Zip', selector: 'zip' },
                    { name: 'Status ', selector: row => row.is_active === 1 ? active() : inactive() },
                    { name: 'Created At ', selector: row => moment(row.created_at).format(themeConfig.app.dateFormat) },
                ]}
            />
        </Fragment>
    )
}

export default Accounts;