import { themeConfig } from "../../../configs/themeConfig"

// ** Auth Endpoints
export default {
  loginEndpoint: `${themeConfig.app.apiUrl}/v1/auth`,
  registerEndpoint: '/jwt/register',
  refreshEndpoint: `${themeConfig.app.apiUrl}user/refresh`,
  logoutEndpoint: '/jwt/logout',
  forgotPassword: `${themeConfig.app.apiUrl}user/forgot-password`,
  meEndPoint: `${themeConfig.app.apiUrl}user/me`,
  // ** This will be prefixed in authorization header with token
  // ? e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // ** Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken'
}      