import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Row, Form, FormGroup, Label, Input, Button, FormFeedback, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify'
import { useSelector, useDispatch } from 'react-redux'
import Geocode from "react-geocode";
import "react-toastify/dist/ReactToastify.css";
import { Submit, Cancel } from "../../../../constant";
import { createOrUpdate } from '../../../../redux/actions/actions';
import { setOpenDrawer } from '../../../../redux/actions/common'
import GoogleMapSearch from '../../../crm/campaigns/list/googleMapSearch'

toast.configure();


const SitesForm = (props) => {
    Geocode.setApiKey("AIzaSyAYNm3oQ_CKCQZ8ueXsC_LElF5quJyQq5g");
    Geocode.setLanguage("en");

    const formSchema = yup.object().shape({
        property_name: yup.string().max(50 , "Property name must be at most 50 characters").required('Please enter property name'),
        property_address: yup.string().max(100 , "Property address must be at most 100 characters").required('Please enter address'),
        region: yup.string().max(20 , "Region must be at most 20 characters").required('Please enter region'),
        property_city: yup.string().max(20 , "Property city must be at most 20 characters").required('Please enter city').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        property_state: yup.string().max(20 , "Property state must be at most 20 characters").required('Please enter state').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
        property_zip: yup.string().max(5 ,"Property zip must be at most 5 characters").required('Please Enter zip code'),
        manager: yup.string().max(20 , "Manager must be at most 20 characters").required('Please enter manager').matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed for this field "),
    })

    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const [dropDownSelection, setDropDownSelection] = useState(1)
    const [spinner, setSpinner] = useState(true);
    const { fetchedRecord } = useSelector(state => state.Common)

    const [addressFields, setAddressFields] = useState({
        region: fetchedRecord?.region || '',
        address: fetchedRecord?.property_address || '',
        city: fetchedRecord?.property_city || '',
        state: fetchedRecord?.property_state || '',
        zip: fetchedRecord?.property_zip || ''
    })
    const dispatch = useDispatch()

    useEffect(() => {
        setDropDownSelection(fetchedRecord.status)

    }, [fetchedRecord.status])

    const directiontoaster = (toastname) => {
        switch (toastname) {
            case 'directionssuccessToast':
                toast.success("Record Successfully Updated!", {
                    position: toast.POSITION.TOP_CENTER,
                });
                break;
            default:
                break;
        }
    }

    const onSubmit = (accountInfo) => {
        setSpinner(false)
        accountInfo['id'] = fetchedRecord.site_id;
        accountInfo['campaign_id'] = props.campaign_id;
        accountInfo['status'] = dropDownSelection;
        accountInfo['coords'] = localStorage.getItem('coords')
        createOrUpdate(accountInfo, 'sites').then(({ data }) => {
            if (!accountInfo.id) {
                let assessment = {}
                assessment['site_id'] = data.response.insertId
                createOrUpdate(assessment, 'assessments')
            }
            directiontoaster('directionssuccessToast')
            dispatch(setOpenDrawer(false))
            setSpinner(true)
            props.getUsers(1)
        })
    }

    const onChnageAddress = ({ position }) => {
        Geocode.fromLatLng(position.lat(), position.lng()).then(json => {
            let formatted_add = json.results[0].formatted_address.split(",");
            let sz = formatted_add[2].split(" ");//split state & zip
            setAddressFields({
                address: formatted_add[0],
                city: formatted_add[1],
                state: sz[1],
                zip: sz[2]
            })
        })
    }


    return (
        <Fragment>

            <Form style={{ marginTop: '4px' }} className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>
                <FormGroup>
                    <GoogleMapSearch coords={fetchedRecord?.coords} onChnageAddress={onChnageAddress}></GoogleMapSearch>
                </FormGroup>
                <FormGroup>
                    <label  ><b>Property Name:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.property_name} name="property_name" type="text" placeholder="Enter Property Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.property_name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Address:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.property_address} value={addressFields.address} onChange={e => setAddressFields({ ...addressFields, address: e.target.value })} name="property_address" type="text" placeholder="Enter Address" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.property_address?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Region:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.region} value={addressFields.region} name='region' type="text" placeholder="Enter Region" innerRef={register({ required: true })} onChange={e => setAddressFields({ ...addressFields, region: e.target.value })} />
                    <span className='text-danger'>{errors?.region?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>City:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.property_city} value={addressFields.city} name="property_city" type="text" placeholder="Enter City" innerRef={register({ required: true })} onChange={e => setAddressFields({ ...addressFields, city: e.target.value })} />
                    <span className='text-danger'>{errors?.property_city?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>State:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.property_state} value={addressFields.state} name='property_state' type="text" placeholder="Enter State" innerRef={register({ required: true })} onChange={e => setAddressFields({ ...addressFields, state: e.target.value })} />
                    <span className='text-danger'>{errors?.property_state?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Zip:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.property_zip} value={addressFields.zip} name='property_zip' type="number" placeholder="Enter Zip Code" innerRef={register({ required: true })} onChange={e => setAddressFields({ ...addressFields, zip: e.target.value })} />
                    <span className='text-danger'>{errors?.property_zip?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Manager:</b></label>
                    <Input className="form-control" defaultValue={fetchedRecord?.manager} name='manager' type="text" placeholder="Enter Manager" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.manager?.message}</span>
                </FormGroup>
                <FormGroup>
                    <label><b>Status:</b></label>
                    <Input className='form-control'
                           name={'is_active'}
                           value={dropDownSelection}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={e => setDropDownSelection(parseInt(e.target.value))} >
                        <option value={0}>Inactive</option>
                        <option value={1}>Active</option>
                    </Input>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" disabled={!spinner}>
                        <Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}
                    </Button>
                    <Button onClick={() => dispatch(setOpenDrawer(false))} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form>

        </Fragment >
    );
};

export default SitesForm;