import React, { Fragment, useEffect, useState } from 'react';
// import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import { Submit, Cancel } from "../../../../constant";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const ClientsContractorsForm = (props) => {

    const formSchema = yup.object().shape({
        name: yup.string().required(),
        email: yup.string().email().required(),
        phone: yup.string().required(),
        company: yup.string().required(),
        userType: yup.string().required(),
    })
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const [options, setOptions] = useState([
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ])
    const [dropDownSelection, setDropDownSelection] = useState(options[0].value)

    useEffect(() => {
    }, [errors])

    const onSubmit = (d) => {
    }

    const handleSelect = selectedValue => {
        setDropDownSelection(selectedValue)
    }

    return (
        <Fragment>
            {/* <Breadcrumb parent="Forms" title="Default Forms"/> */}

            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Input className="form-control" name="name" type="text" placeholder="Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.name?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="email" type="text" placeholder="Email" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.email?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="phone" type="text" placeholder="phone" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.phone?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="company" type="text" placeholder="company" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.company?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="userType" type="text" placeholder="User Type" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.userType?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input defaultValue={options[0].value} type='select' style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }} onChange={(e) => handleSelect(e.target.value)} >
                        {
                            options.map((v, i) => {
                                return <option key={i}>{v.value}</option>
                            })
                        }
                    </Input>
                    <span className='text-danger'>{errors?.status?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" >{Submit}</Button>
                    <Button onClick={() => props.setOpenDrawer(false)} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form>

        </Fragment>
    );
};

export default ClientsContractorsForm;