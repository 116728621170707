import React from 'react'



const Settings = () => {

    return (
        <div></div>
    )
}

export default Settings;