const initialState = {
    filters: {
        startDate: '',
        endDate: '',
        keyword: '',
        page: 1,
        perPage: 10
    },
    fetchedRecord: {},
    openDrawer: false,
    loading:false
}

const commonReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_FILTERS':
            return { ...state, filters: action.payload }

        case 'SET_FETCHED_RECORD':
            return { ...state, fetchedRecord: action.payload }

        case 'SET_OPEN_DRAWER':
            return { ...state, openDrawer: action.payload }

        case 'LOADING':
            return { ...state, loading: action.payload }

        default: return { ...state };
    }

}

export default commonReducer
