// ** UseJWT import to get config

import useJwt from "../../../auth/useJwt"
import {loading} from "../common";

// import useJwt from '@src/auth/jwt/useJwt'


const config = useJwt.jwtConfig

// ** Handle User Login
export const handleLogin = (data) => {
    return dispatch => {
        // dispatch(loading(true))
        dispatch({
            type: 'LOGIN',
            data,
            // config,
            // [config.storageTokenKeyName]: data[config.storageTokenKeyName],
            // [config.storageRefreshTokenKeyName]: data[config.storageRefreshTokenKeyName]
        })
        // ** Add to user, accessToken & refreshToken to localStorage
        //  localStorage.setItem('userData', JSON.stringify(data))
        //  localStorage.setItem(config.storageTokenKeyName, data.accessToken)
        // localStorage.setItem(config.storageRefreshTokenKeyName, data.refreshToken)
        // console.log(localStorage.getItem('userData'))
        // fetchLocalStorage()
    }
}

// ** Handle User Logout
export const handleLogout = () => {
    return dispatch => {
        dispatch({ type: 'LOGOUT' })
    }
}
