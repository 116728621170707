import axios from './api';
import { themeConfig } from '../../configs/themeConfig'

const { apiUrl } = themeConfig.app;

export const fetchDBTable = (filters, path) => (dispatch) => axios.post(`${apiUrl}/v1/${path}`, filters).then(res => res)
export const fetchCampaigns = path => dispatch => axios.post(`${apiUrl}/v1/${path}`).then(res => res)
export const fetchData = path => dispatch => axios.get(`${apiUrl}/v1/${path}`).then(res => res)
export const fetchById = (filter, path) => axios.post(`${apiUrl}/v1/${path}`, filter)
export const createOrUpdate = (account, path) => axios.post(`${apiUrl}/v1/${path}/createOrUpdate`, account)
export const deleteById = (account, path) => axios.post(`${apiUrl}/v1/${path}/delete`, account)

