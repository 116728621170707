import Default from '../components/dashboard/default'
import Contacts from '../modules/crm/contacts/list'
import Campaigns from '../modules/crm/campaigns/list'
import CampaignDetails from '../modules/crm/campaigns/list/campaignDetails'
import Accounts from '../modules/crm/accounts/list'
import Dashboard from '../modules/mainMenu/dashboard/list'
import Assessments from '../modules/projectsAndDrawings/assesments/list'
import Maps from '../modules/projectsAndDrawings/maps/list'
import Sites from '../modules/projectsAndDrawings/sites/list'
import ClientsAndContractor from '../modules/appUsersAndReports/clientsAndContractors/list'
import WorkOrders from '../modules/appUsersAndReports/workOrders/list'
import Proposals from '../modules/appUsersAndReports/proposals/list'
import InternalUsers from '../modules/appUsersAndReports/internalUsers/list'
import Settings from '../modules/management/settings/list'
import Signin from '../modules/signIn/signIn'


export const routes = [
        { path: `/dashboard`, Component: Dashboard,exact:true },
        { path: `/signin`, Component: Signin,exact:true },
        { path: `/accounts/`, Component: Accounts,exact:true },
        { path: `/contacts/`, Component: Contacts,exact:true },
        { path: `/campaigns/`, Component: Campaigns ,exact:true},
        { path: `/campaigns/campaign-details/:id`, Component: CampaignDetails,exact:false },
        { path: `/sites/`, Component: Sites,exact:true },
        { path: `/assessments/:id`, Component: Assessments,exact:true },
        { path: `/maps/`, Component: Maps,exact:true },
        { path: `/clients-contractors/`, Component: ClientsAndContractor,exact:true },
        { path: `/work-orders/`, Component: WorkOrders,exact:true },
        { path: `/proposals/`, Component: Proposals,exact:true },
        { path: `/internal-Users/`, Component: InternalUsers,exact:true },
        { path: `/settings/`, Component: Settings,exact:true },
]