import React, { Fragment, useState, useEffect } from 'react';
import { useSelector } from 'react-redux'
import { MENUITEMS } from './menu';
import {ArrowRight, ArrowLeft, Grid, Menu} from 'react-feather';
import { Link } from 'react-router-dom'
import { translate } from 'react-switch-lang';
import configDB from '../../data/customizer/config';
import { DefaultLayout } from '../theme-customizer';


const Sidebar = (props) => {
  const id = window.location.pathname.split('/').pop()
  const defaultLayout = Object.keys(DefaultLayout);
  const layout = id ? id : defaultLayout
  // eslint-disable-next-line
  const [mainmenu, setMainMenu] = useState(MENUITEMS);
  const [margin, setMargin] = useState(0);
  const [width, setWidth] = useState(0);
  const [sidebartoogle, setSidebartoogle] = useState(true)
  const wrapper = useSelector(content => content.Customizer.sidebar_types.type) || configDB.data.settings.sidebar.type;
  const handleScroll = () => {
    if (window.scrollY > 400) {
      if (configDB.data.settings.sidebar.type.split(' ').pop() === 'material-type' || configDB.data.settings.sidebar.type.split(' ').pop() === 'advance-layout')
        document.querySelector(".sidebar-main").className = "sidebar-main hovered"
    } else {
      document.querySelector(".sidebar-main").className = "sidebar-main"
    }
  }
  useEffect(() => {
    document.querySelector(".left-arrow").classList.add("d-none")
    window.addEventListener('resize', handleResize)
    handleResize();
    const currentUrl = window.location.pathname;
    MENUITEMS.map(items => {
      items.Items.filter((Items) => {
        if (Items.path === currentUrl)
          setNavActive(Items)
        return Items
      })
      return items
    })
    window.addEventListener('scroll', handleScroll)
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }

    // eslint-disable-next-line
  }, [layout]);

  const handleResize = () => {
    setWidth(window.innerWidth - 500);
  }

  const setNavActive = (item) => {
    MENUITEMS.map(menuItems => {
      menuItems.Items.filter(Items => {
        if (Items.title == item.title) {
          Items.active = true
        }else{
          Items.active = false
          document.querySelector(".sidebar-link").classList.remove("active")
        }
        return Items
      })
      return menuItems
    })
    // item.active = !item.active
    setMainMenu({ mainmenu: MENUITEMS })
  }

  const scrollToRight = () => {
    if (margin <= -2598 || margin <= -2034) {
      if (width === 492) {
        setMargin(-3570)
      } else {
        setMargin(-3464)
      }
      document.querySelector(".right-arrow").classList.add("d-none")
      document.querySelector(".left-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += (-width));
      document.querySelector(".left-arrow").classList.remove("d-none")
    }
  }

  const scrollToLeft = () => {
    if (margin >= -width) {
      setMargin(0)
      document.querySelector(".left-arrow").classList.add("d-none")
      document.querySelector(".right-arrow").classList.remove("d-none")
    } else {
      setMargin(margin => margin += width);
      document.querySelector(".right-arrow").classList.remove("d-none")
    }
  }

  const closeOverlay = () => {
    document.querySelector(".bg-overlay1").classList.remove("active")
    document.querySelector(".sidebar-link").classList.remove("active")
  }

  const activeClass = () => {
    document.querySelector(".sidebar-link").classList.add("active")
    document.querySelector(".bg-overlay1").classList.add("active")
  }

  const openCloseSidebar = (toggle) => {
    if (toggle) {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header close_icon";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon "
    } else {
      setSidebartoogle(!toggle);
      document.querySelector(".page-header").className = "page-header";
      document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper"
    }
  };

  const responsiveSidebar = () => {
    document.querySelector(".page-header").className = "page-header close_icon";
    document.querySelector(".sidebar-wrapper").className = "sidebar-wrapper close_icon"
  }

  return (
      <Fragment>
        <div className={`bg-overlay1`} onClick={() => { closeOverlay() }} ></div>
        <div className="sidebar-wrapper">
          <div className="logo-wrapper" style={{padding:'5px 10px 0px 0px'}}>
            <Link to={`${process.env.PUBLIC_URL}/dashboard/`} >
              <img className="img-fluid for-light" style={{padding: '10px 0px 10px 0px',maxWidth: '60%',margin: '0px 0px 0px 38px'}} src={require("../../assets/images/logo/logo.png")} alt="" />
              <img className="img-fluid for-dark" src={require("../../assets/images/logo/logo.png")} style={{padding: '10px 0px 10px 0px',maxWidth: '60%',margin: '0px 0px 0px 38px'}} alt="" />
            </Link>
            <div className="back-btn" onClick={() => responsiveSidebar()}><i className="fa fa-angle-left"></i></div>
            <Menu  style={{position:'absolute' , right:'30px' , top:'30px' , cursor:'pointer'}} onClick={() => openCloseSidebar(sidebartoogle)}>
              {/* <div>< className='toggle-sidebar'> */}
              <Grid className="status_toggle middle sidebar-toggle"/>
            </Menu>
          </div>
          <div className="logo-icon-wrapper" style={{padding:'30px 5px 30px 5px'}} >
            <Link to={`${process.env.PUBLIC_URL}/dashboard/${layout}`}>
              <img style={{height:'30px',width:'86px'}} className="img-fluid" src={require("../../assets/images/logo/logo.png")} alt="" /></Link>
          </div>
          <nav className="sidebar-main">
            <div className="left-arrow" onClick={scrollToLeft}><ArrowLeft /></div>
            <div id="sidebar-menu" style={wrapper.split(' ').includes('horizontal-wrapper') ? { 'marginLeft': margin + 'px' } : { margin: '0px' }}>
              <ul className="sidebar-links custom-scrollbar" >
                <li className="back-btn">
                  <div className="mobile-back text-right"><span>{"Back"}</span><i className="fa fa-angle-right pl-2" aria-hidden="true"></i></div>
                </li>
                {
                  MENUITEMS.map((Item, i) =>
                      <Fragment key={i}>
                        <li className="sidebar-main-title">
                          <div>
                            <h6 className="lan-1">{props.t(Item.menutitle)}</h6>
                            <p className="lan-2">{props.t(Item.menucontent)}</p>
                          </div>
                        </li>
                        {Item.Items.map((menuItem, i) =>
                            <li className="sidebar-list" key={i}>

                              {(menuItem.type === 'link') ?
                                  <Link to={menuItem.path} className={`sidebar-link sidebar-title link-nav  ${menuItem.active ? 'active' : ''}`}>
                                    <menuItem.icon />
                                    <span>{props.t(menuItem.title)}</span>
                                    {menuItem.badge ? <label className={menuItem.badge}>{menuItem.badgetxt}</label> : ""}
                                  </Link>
                                  : ''}

                            </li>)}
                      </Fragment>
                  )}
              </ul>
            </div>
            <div className="right-arrow" onClick={scrollToRight}><ArrowRight /></div>
          </nav>
        </div>
      </Fragment>
  );
}

export default translate(Sidebar);