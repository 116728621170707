import React, { Fragment, useEffect, useState } from "react";
import Pagination from 'react-js-pagination'
import './pagination.css'
import { useDispatch, useSelector } from "react-redux";
import { setFilters } from "../../redux/actions/common";

const PaginationComponent = ({ rowsPerPage, totalNoOfResults, getUsers }) => {

    const { filters } = useSelector(state => state.Common)
    const dispatch = useDispatch()

    const handlePageChange = page => {

        try {
            getUsers(page)
            dispatch(setFilters({ ...filters, page }))
        }
        catch {
            console.warn('error in handlepage change func in pagination')
        }
    }

    return (
        <Fragment>

            <div style={{ display: 'flex', flexDirection: 'column', marginTop: '10px', marginBottom: '10px', marginRight: '5px' }}>
                <div style={{ alignSelf: 'flex-end' }}>
                    <Pagination

                        aria-label="Page navigation example"
                        itemClass="page-item"
                        linkClass="page-link"
                        prevPageText="<<"
                        nextPageText=">>"
                        firstPageText="First"
                        lastPageText="Last"
                        activePage={filters.page}
                        itemsCountPerPage={rowsPerPage}
                        totalItemsCount={totalNoOfResults}
                        onChange={handlePageChange}
                    />
                </div>
            </div>


        </Fragment >
    )

}

export default PaginationComponent;