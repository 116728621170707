import React, { Fragment, useEffect, useState } from 'react';
import {GoogleMap, InfoBox, InfoWindow, Marker, useJsApiLoader} from "@react-google-maps/api";
import Breadcrumb from '../../../../layout/breadcrumb'
import {Card, CardBody, Container, Row, Col, Button} from 'reactstrap'
import moment from 'moment';
import './index.css'
import Sites from '../../../projectsAndDrawings/sites/list';
import Loader from "../../../../components/spinner/spinner";
import { fetchZones } from "../../../../redux/actions/zones";
import {useDispatch, useSelector} from "react-redux";
import {Link, useParams} from 'react-router-dom'
import {setFilters} from "../../../../redux/actions/common";
import {fetchDBTable} from "../../../../redux/actions/actions";
import {themeConfig} from "../../../../configs/themeConfig";
import Rating from "react-rating";
import Spinner from "../../../../components/spinner/spinner";

const CampaignDetails = (props) => {
    const dispatch = useDispatch()
    const [showInfo, setShowInfo] = useState(false);
    const [sites, setSites] = useState([])
    const common = useSelector(state => state.Common)
    const [spinner, setSpinner] = useState(true);
    const [info, setInfo] = useState({})
    const [campaign, setCampaign] = useState({})
    const { id } = useParams()

    useEffect(() => {
        dispatch(setFilters({ ...common.filters, id: id }))
        fetchAndSetStates()
    }, [])

    const fetchAndSetStates = () => {
        getCampaigns()
        getSites()
    }
    const getCampaigns = () => {
        dispatch(fetchDBTable({ ...common.filters }, 'campaigns'))
            .then(({ data }) => {
                setCampaign(data.response.find(e => e.id == id))
            })
    }
    const getSites = () => {
        dispatch(fetchZones('site/assessment', { 'id': id }))
            .then(({ data }) => {
                setSites(data.response)
            }).catch(err => {
                console.log(err)
            })
    }

    const containerStyle = {
        width: 'auto',
        height: 600
    };
    const getMapCenter = () => {
        if(sites && sites[0]){
            let coords = JSON.parse(sites[0].coords)
            return coords
        }else{
            return { lat: 41.85003, lng: -87.65005 }
        }
    }

    const { isLoaded } = useJsApiLoader({
        libraries: ['drawing','places'],
        id: 'google-map-script',
        googleMapsApiKey: themeConfig.app.apiKey
    })

    const renderShapes = site => {
        let { id, coords, } = site
        if(coords) {
            let cords = JSON.parse(coords)
            return (
                <div key={id}>
                    <Marker position={cords}>
                        {site && infoBox(site)}
                    </Marker>
                </div>
            )
        }
    }

    const openInfo = (site) => {
        setInfo(site);
        setShowInfo(true);
    }

    const infoBox = (site, purpose = 'label') => {
        let { coords, property_name } = site;
        coords = JSON.parse(coords);
        return <InfoBox
            position={{ lat: coords.lat, lng: coords.lng }}
            options={{
                pixelOffset: new window.google.maps.Size(-30, -70),
                enableEventPropagation: true,
                boxStyle: {
                    padding: '2px'
                },
                closeBoxURL: ``,
            }} >
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <div className='bordered-dark bg-light' style={{ padding: '4px', border: '1px solid black', color: 'black' }}>
                    <a onClick={() => openInfo(site)}>{property_name}</a>
                </div>
            </div>
        </InfoBox>;
    }

    const renderInfoWindow = () => {
       let { coords } = info;
       coords = JSON.parse(coords);
       return <InfoWindow
           position={{ lat: coords.lat, lng: coords.lng }}
           zIndex={1}
           onCloseClick={() => setShowInfo(false)}
       >
           <div>
               <div className='d-flex flex-row justify-content-space-between align-items-center'>
                   <h5 className="mb-0 mr-2">{info.property_name}</h5>
                   <img style={{maxWidth: '60%', width:'100px', height:'40px'}} src={require("../../../../assets/images/logo/logo.png")} alt="" />
               </div>
               <hr className="mb-1 mt-0" />
               <div className='d-flex flex-column'>
                   <b className="mb-0">Status</b>
                   <p className={`badge ${info.status === 1 ? 'mb-0 badge-success' : 'mb-0 badge-danger'}`} style={{margin:'0px 50px 10px 50px'}}>Active</p>
                   <b className="mb-0">Manager</b>
                   <p className="mb-0">{info.manager}</p>
                   <b className="mb-0">Region</b>
                   <p className="mb-0">{info.region}</p>
                   <b className="mb-0">Address</b>
                   <p className="mb-0">{info.property_address}</p>
                   <b className="mb-0">Paser Rating</b>
                   <Rating
                       style={{color:'#ffa800'}}
                       initialRating={info.paser}
                       emptySymbol="fa fa-star-o"
                       fullSymbol="fa fa-star "
                       // onChange={(rate) => setRating(info.paser)}
                   >
                   </Rating>

                   <div style={{display:'inline-block'}} className='mt-2'>

                       {/*<Link to={`${process.env.PUBLIC_URL}/assessments/${info.assessment_id}`} style={{ cursor: 'pointer',color:'#000' }}>*/}
                       {/*    <Button className='btn-xs mr-2'>Edit</Button>*/}
                       {/*</Link>*/}

                       <Link to={`${process.env.PUBLIC_URL}/assessments/${info.assessment_id}`} style={{ cursor: 'pointer',color:'#000' }}>
                           <Button className='btn-xs mr-2'>Assessment</Button>
                       </Link>

                       {/*<Link to={`${process.env.PUBLIC_URL}/assessments/${info.assessment_id}`} style={{ cursor: 'pointer',color:'#000' }}>*/}
                       {/*    <Button className='btn-xs'>Delete</Button>*/}
                       {/*</Link>*/}

                   </div>

               </div>
           </div>
       </InfoWindow>;
    }

    const shortName = (str) =>{
        let acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
        return acronym.substring(0, 2).toUpperCase();
    }

    return isLoaded && Object.keys(campaign).length > 0 ?  (
        <Fragment>
            <Breadcrumb parent="Campaigns" title={<h3 style={{ marginLeft: '20px' }} >Campaign Details</h3>} />
            <Container fluid={true}>
                <Row>
                    <Col md="12" className="project-list" >

                    </Col>
                    <Col sm="12" >
                        <Row>
                            <Col sm="12"  >
                                <div className="project-box">

                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center', marginBottom: '10px' }} >
                                        <div className={`badge ${campaign.is_active === 1 ? 'badge-success' : 'badge-danger'}`}>{campaign?.is_active === 1 ? 'Active' : 'Inactive'}</div>
                                    </div>
                                    <h6 style={{ marginBottom: '10px' }}>{campaign.campaign_name}</h6>
                                    <div className="media">
                                        <div style={{borderRadius:'50%', padding:'10px', marginRight:'10px', backgroundColor: '#fbbb1a', width: '42px', color: '#fff', textAlign: 'center'}}>
                                            <b>{shortName(campaign.first_name)}</b>
                                        </div>
                                        <div className="media-body">
                                            <span>Created By <b style={{ color: 'black' }}>{campaign.first_name + ' ' + campaign.last_name}</b></span>
                                            <br/>
                                            <span>Account Name: <b style={{ color: 'black' }}>{campaign.name}</b></span>
                                        </div>
                                    </div>
                                    <div style={{ textAlign:'justify' }}>{campaign?.description}</div>
                                    <Row className="details mt-4">
                                        <Col xs="2"><span>Number of sites</span></Col>
                                        <Col xs="2" className={campaign.is_active === 1 ? 'text-success' : 'text-danger'}>{campaign.total_sites}</Col>
                                    </Row>
                                    <Row className="details mt-1">
                                        <Col xs="2" ><span>{'Created At'} </span></Col>
                                        <Col xs="2" style={{ color: campaign.is_active === 0 ? '#dc3545' : '#51bb25' }}>{moment(campaign.created_at).format('MM-DD-YYYY')}</Col>
                                    </Row>
                                </div>
                            </Col>

                        </Row>
                    </Col>
                </Row>

               <Row className='mt-5'>
                    <Col sm="12" >
                        <Card>
                            <CardBody>
                                {sites ?
                                <GoogleMap
                                    center={getMapCenter()}
                                    id="drawing-manager-example"
                                    mapContainerStyle={containerStyle}
                                    zoom={10}
                                    mapTypeId="satellite"
                                >
                                    {sites && sites.map(el => renderShapes(el))}
                                    {showInfo && renderInfoWindow()}
                                </GoogleMap>
                                :<Loader/>}
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

                <Row>
                    <Col md="12" className="project-list" >

                    </Col>
                    <Col sm="12" >
                        <Row>
                            <Sites campaign_id={id} path={'campaign/sites'} />
                        </Row>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    ) : <Spinner />
}

export default CampaignDetails;