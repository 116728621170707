import React, {Fragment, useEffect, useState} from 'react';
import { Disc } from 'react-feather';
import { Drawer} from 'rsuite';
import "react-toastify/dist/ReactToastify.css";
import './index.css'
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import SitesForm from '../sidebar/form';
import DataTable from '../../../crm/table';
import { setFetchedRecord, setOpenDrawer } from '../../../../redux/actions/common';
import { themeConfig } from '../../../../configs/themeConfig';


const Sites = ({ path, campaign_id }) => {
    const [isEdit, setIsEdit] = useState(false);
    const common = useSelector(state => state.Common)
    const dispatch = useDispatch();
    const active = () => {
        return (
            <div style={{ backgroundColor: '#38b000', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Active</span>
            </div>
        )
    }

    const inactive = () => {
        return (
            <div style={{ backgroundColor: '#e63946', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Inactive</span>
            </div>
        )
    }

    const handleClose = () => {
        dispatch(setOpenDrawer(false));
        dispatch(setFetchedRecord({}))
    }

    const drawerComp = (props) => {
        return (
            <Drawer open={common.openDrawer} onClose={handleClose} size={'sm'} style={{ width: '25%' }}>
                <Drawer.Header><Drawer.Title>{Object.keys(common.fetchedRecord).length > 0 ? 'Edit Sites' : 'Create Sites'}</Drawer.Title></Drawer.Header>
                <Drawer.Body >
                    <SitesForm
                        isEdit={isEdit}
                        site_id={common.fetchedRecord.site_id}
                        campaign_id={campaign_id}
                        getUsers={props.getUsers}
                    />
                </Drawer.Body>
            </Drawer>
        )
    }

    return (
        <Fragment>
            <DataTable
                setIsEdit={setIsEdit}
                api={path}
                fetchApi={'sites/get'}
                deleteApi={'sites'}
                drawerComp={drawerComp}
                setOpenDrawer={setOpenDrawer}
                screenTitle={'Sites'}
                searchFilterName={'name'}
                additionalBtn={Disc}
                tableColumnsName={[
                    { name: 'Name', selector: 'property_name' },
                    { name: 'Region', selector: 'region' },
                    { name: 'Address', selector: 'property_address' },
                    { name: 'City', selector: 'property_city' },
                    { name: 'State', selector: 'property_state' },
                    { name: 'Zip', selector: 'property_zip' },
                    { name: 'Manager', selector: 'manager' },
                    { name: 'Status ', selector: row => row.is_active === 1 ? active() : inactive() },
                    { name: 'Created At ', selector: row => moment(row.created_at).format(themeConfig.app.dateFormat) },
                ]}
            />
        </Fragment>
    )
}

export default Sites;