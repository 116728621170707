import React, {Fragment, useState} from 'react';
import { Form, FormGroup, Input, Button, Label, Spinner } from 'reactstrap'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import {createOrUpdate} from "../../../../redux/actions/actions";
import {toast} from "react-toastify";
import {Submit} from "../../../../constant";

const ZoneForm = (props) => {

    const formSchema = yup.object().shape({
        zone_title: yup.string().required('Please enter zone title'),
        area_unit: yup.string(),
        description: yup.string()
    })
    const [spinner, setSpinner] = useState(true);
    const [areaUnit, setAreaUnit] = useState(true);
    const { register, handleSubmit, errors } = useForm({mode: 'onChange', resolver: yupResolver(formSchema) });

    const onSubmit = (form) => {
        setSpinner(false)
        let { zone, setZone, getZone } = props
        if(zone){
            zone.zone_title = form.zone_title
            zone.area_unit = areaUnit.length > 0 ?  areaUnit : props.zone && props.zone.area_unit
            zone.description = form.description
            createOrUpdate(zone,'zones').then(res => {
                if(res.status){
                    props.setOpenDrawer(false)
                    toast.success("Record Successfully Updated!", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setZone({})
                    getZone()
                    setSpinner(true)
                }
            }).catch(error => {
                toast.error(error, {
                    position: toast.POSITION.TOP_CENTER,
                });
            })
        }
    }

    const handleClose = () => {
        let { zone ,setZone, deleteZone } = props
        if(!zone.id){
            deleteZone(zone.id)
        }
        setZone({})
        props.setOpenDrawer(false)
    }

    const handleAreaUnit = (e) => {
        setAreaUnit(e.target.value)
    }

    return (
        <Fragment>
            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Label><b>Zone Title</b></Label>
                    <Input className="form-control"
                           name="zone_title"
                           type="text"
                           defaultValue={props.zone && props.zone.zone_title}
                           placeholder="Zone Title" innerRef={register({ required: true })}
                    />
                    <span className='text-danger'>{errors?.zone_title?.message}</span>
                </FormGroup>

                <FormGroup>
                    <label><b>Area Unit</b></label>
                    <Input className='form-control'
                           name='area_unit'
                           defaultValue={props.zone && props.zone.area_unit}
                           type='select'
                           style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }}
                           onChange={handleAreaUnit}
                    >
                        <option value='m'>Meter</option>
                        <option value='y'>Yards</option>
                    </Input>
                </FormGroup>

                <FormGroup>
                    <Label><b>Description</b></Label>
                    <Input className="form-control"
                           name="description"
                           type="textarea"
                           defaultValue={props.zone && props.zone.description}
                           placeholder="Description" innerRef={register({ required: false })}
                    />
                </FormGroup>

                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" disabled={!spinner}>
                        <Spinner hidden={spinner} style={{ marginRight: '5px' }} size={'sm'} ></Spinner>{Submit}
                    </Button>
                    <Button onClick={handleClose} color="secondary">{props.zone && props.zone.id ?'Cancel' :'Delete'}</Button>
                </FormGroup>

            </Form>
        </Fragment>
    );
};

export default ZoneForm;