import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux'
import { setContacts } from "../../redux/actions/contacts";
import { Input } from 'reactstrap'
import "rsuite/dist/rsuite.min.css";
import { DateRangePicker } from 'rsuite'
import moment from 'moment'
import './header.css'
import { setFilters, setOpenDrawer, setFetchedRecord } from '../../redux/actions/common'
import { setCampaigns } from "../../redux/actions/campaigns";
import { fetchDBTable } from '../../redux/actions/actions';
import { Plus } from "react-feather";

const Header = (props) => {
    const dispatch = useDispatch()
    const common = useSelector((state) => state.Common)
    const [pageSize, setPageSize] = useState(['10', '20', '50', '100'])
    const [accountId, setAccountId] = useState('');

    const handleDrawer = () => {
        if (props.api === 'campaigns') {
            props.setFetchRecord({})
            props.setOpenDrawer(true)
        }
        else {
            dispatch(setFetchedRecord({}))
            dispatch(setOpenDrawer(true))
        }
    }

    const handleDatePicker = date => {
        if (date !== null) {
            { props.api !== 'campaigns' ? props.setLoading(true) : props.setCampaignLoading(true) }
            let startDate = moment(date[0]).format('YYYY-MM-DD');
            let endDate = moment(date[1]).format('YYYY-MM-DD');
            dispatch(setFilters({ ...common.filters, startDate, endDate, page:1 }))
            fetchDBTable({ ...common.filters, startDate, endDate, page:1 }, props.api)('', '').then(({ data }) => {
                props.api !== 'campaigns' ? dispatch(setContacts(data.response)) : dispatch(setCampaigns(data.response))
                { props.api !== 'campaigns' ? props.setLoading(false) : props.setCampaignLoading(false) }
            })
        }
        else {
            { props.api !== 'campaigns' ? props.setLoading(true) : props.setCampaignLoading(true) }
            dispatch(setFilters({ ...common.filters, startDate: '', endDate: '' }))
            fetchDBTable({ ...common.filters, startDate: '', endDate: '' }, props.api)('', '').then(({ data }) => {
                props.api !== 'campaigns' ? dispatch(setContacts(data.response)) : dispatch(setCampaigns(data.response))
                { props.api !== 'campaigns' ? props.setLoading(false) : props.setCampaignLoading(false) }
            })
        }
    }

    const handlePageSize = size => {
        props.setLoading(true)
        dispatch(setFilters({ ...common.filters, perPage: size, page: 1 }))
        fetchDBTable({ ...common.filters, perPage: size, page: 1 }, props.api)('', '').then(({ data }) => {
            dispatch(setContacts(data.response))
            props.setLoading(false)
        })
    }

    const onPressEnter = value => {
        { props.api !== 'campaigns' ? props.setLoading(true) : props.setCampaignLoading(true) }
        dispatch(setFilters({ ...common.filters, keyword: value }))
        if (props.api !== 'campaigns') {
            fetchDBTable({ ...common.filters, keyword: value }, props.api)('', '').then(({ data }) => {
                dispatch(setContacts(data.response))
                { props.api !== 'campaigns' && props.setLoading(false) }
            })
        } else {
            { props.api !== 'campaigns' ? props.setLoading(true) : props.setCampaignLoading(true) }
            fetchDBTable({ ...common.filters, keyword: value }, props.api)('', '').then(({ data }) => {
                dispatch(setCampaigns(data.response))
                { props.api !== 'campaigns' ? props.setLoading(false) : props.setCampaignLoading(false) }
            })
        }
    }

    const filterByAccountId = e => {
        props.setLoading(true)
        setAccountId(e.target.value)
        const request = e.target.value != '' ? { id: parseInt(e.target.value), api: 'account/contacts' } : { endDate: "", keyword: "", perPage: 10, startDate: "", api: 'contacts' };
        fetchDBTable(request, request.api)('', '').then(({ data }) => {
            dispatch(setContacts(data.response))
            props.setLoading(false)
        })
    }

    return (
        <Fragment>
            <div className='header-main-container'>

                <div onClick={handleDrawer} className='plus-btn-div'>
                    <Plus size={30} />
                </div>

                <div className='select-daterange-div' style={{ width: '100%' }}>
                    {props.api !== 'campaigns' && <span style={{ color: 'black', marginRight: '10px', marginLeft: '5px' }}>showing</span>}
                    {props.api !== 'campaigns' &&
                    <Input type='select' style={{ height: '35px', width: '75px', borderRadius: 8, backgroundColor: '#f8f8f8', border: 'none' }}
                           onChange={e => handlePageSize(e.target.value)}
                    >
                        {pageSize.map((v, i) => {
                            return <option key={i}>{v}</option>
                        })
                        }
                    </Input>}

                    <span style={{ color: 'black', marginLeft: '10px', marginRight: '10px' }}>records during</span>
                    <DateRangePicker
                        format='MM/dd/yyyy'
                        appearance='subtle'
                        character='    -    '
                        onChange={handleDatePicker}
                        placeholder="Select Date Range"
                        style={{ width: '25%', marginLeft: '10px', backgroundColor: '#f8f8f8', borderRadius: 8 }}
                    />

                    {props.accountsName &&
                    <Input className='form-control' name={'account_name'} value={accountId} type='select'
                           style={{ height: '35px', borderRadius: 8, cursor: 'pointer', width: '25%', marginLeft: '10px', marginRight: '10px', backgroundColor: '#f8f8f8', border: 'none' }}
                           onChange={e => filterByAccountId(e)} >
                        <option value=''>Select Accounts</option>
                        {
                            props?.accountsName?.map((item, index) => {
                                return <option key={index} value={item.id} >{item.name}</option>
                            })
                        }
                    </Input>
                    }

                    <Input
                        className="form-control "
                        value={props.searchText}
                        type="text"
                        placeholder="Search keyword"
                        style={{ marginLeft: 'auto', marginRight: '5px', height: '35px', backgroundColor: '#f8f8f8', borderRadius: 8, color: '#a6a6a6', width: '25%' }}
                        onKeyDown={(e) => e.key === 'Enter' && onPressEnter(e.target.value)}
                    />
                </div>

            </div>
        </Fragment>
    )
}

export default Header;