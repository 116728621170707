export const themeConfig = {
    app: {
        apiUrl: 'https://tpgapi.appstru.com',
        dateFormat: 'MM/DD/YYYY',
        apiKey:"AIzaSyAYNm3oQ_CKCQZ8ueXsC_LElF5quJyQq5g",
        MAP_INITIALS: {
            LATITUDE: 37.8025259,
            LONGITUDE: -122.4351431,
            LATITUDE_DELTA: 0.0922,
            LONGITUDE_DELTA: 0.0922 * 10,
            MAP_TYPE: "standard"
        }
    }
}
