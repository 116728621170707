import React, { Fragment, useState } from 'react'
import DataTable from '../../../crm/table'
import { Edit, Trash2, ExternalLink } from 'react-feather'
import { Drawer, Placeholder } from 'rsuite';

import ClientsContractorsForm from '../sidebar/form';

const ClientsAndContractor = () => {
    const [openDrawer, setOpenDrawer] = useState(false)

    const drawer = (isOpen) => {
        setOpenDrawer(isOpen)
    }
    return (
        <Fragment>
            <Drawer open={openDrawer} onClose={() => drawer(false)} size={'sm'} style={{ width: '25%' }}>
                <Drawer.Header>
                    <Drawer.Title>Clients and Contractor Form</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body >
                    {/* <Placeholder.Paragraph /> */}
                    <ClientsContractorsForm setOpenDrawer={setOpenDrawer} />
                </Drawer.Body>
            </Drawer>
            <DataTable
                setOpenDrawer={setOpenDrawer}
                screenTitle={'Clients and Contractors'}
                api={'https://retoolapi.dev/lCnL1a/data'}
                tableColumnsName={[
                    { name: 'Name', selector: row => 'Brian' },
                    { name: 'Email', selector: row => 'brian@thepavementgroup.com' },
                    { name: 'Phone', selector: row => 'Mars' },
                    { name: 'Company', selector: row => 'PA' },
                    { name: 'User Type', selector: row => 'User Type' },
                    { name: 'Status ', selector: row => <div style={{ backgroundColor: '#38b000', borderRadius: '12px', paddingRight: '10px', paddingLeft: '10px' }} ><p style={{ color: 'white' }}>Active</p></div> },
                    { name: 'Action', selector: row => <div><Edit style={{ cursor: 'pointer' }} size={14} /><Trash2 style={{ cursor: 'pointer', marginLeft: '10px', marginRight: '10px' }} size={15} /><ExternalLink style={{ cursor: 'pointer' }} size={15} /></div> },

                ]}
            />
        </Fragment>
    )
}

export default ClientsAndContractor;