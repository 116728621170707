import React, {Fragment, useEffect, useState} from 'react'
import Breadcrumb from "../../../../layout/breadcrumb";
import {Col, Container, Row} from "reactstrap";
import Header from "../../../../components/header/header";
import Spinner from "../../../../components/spinner/spinner";
import Loader from "../../../../components/spinner/spinner";
import {useDispatch, useSelector} from "react-redux";
import {loading} from "../../../../redux/actions/common";


const Dashboard = () => {

    const dispatch = useDispatch()
    const common = useSelector(state => state.Common)

    useEffect(() => {
        dispatch(loading(false))
    }, [])

    // if (loading) {
    //     return (
    //         <Fragment>
    //             <Breadcrumb title={<h3 style={{ marginLeft: '20px' }} >Dashboard</h3>} />
    //             <Container fluid={true}>
    //                 <Row>
    //                     <Spinner />
    //                 </Row>
    //             </Container>
    //         </Fragment>
    //     )
    // }
    // else {
    // if(common.loading){
    //     return <Loader />
    // }else{
        return (
            <Fragment>
                {/*<Breadcrumb title={<h3 style={{ marginLeft: '20px' }} >Dashboard</h3>} />*/}
                <Container fluid={true}>
                    <Row>
                        <Col sm="12" >

                        </Col>
                    </Row>
                </Container>
            </Fragment>
        )
    // }
}

export default Dashboard;