import React, { Fragment, useEffect, useState } from 'react';
// import Breadcrumb from '../../../layout/breadcrumb'
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter, Form, FormGroup, Label, Input, Button, FormFeedback } from 'reactstrap'
import { Submit, Cancel } from "../../../../constant";
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'

const WorkOrdersForm = (props) => {

    const formSchema = yup.object().shape({
        clientName: yup.string().required(),
        scope: yup.string().required(),
        material: yup.string().required(),
        plan: yup.string().required(),
    })
    const { register, handleSubmit, errors } = useForm({ mode: 'onChange', resolver: yupResolver(formSchema) });

    const [options, setOptions] = useState([
        { value: 'Active', label: 'Active' },
        { value: 'Inactive', label: 'Inactive' },
    ])
    const [dropDownSelection, setDropDownSelection] = useState(options[0].value)

    useEffect(() => {
        console.log(errors)

    }, [errors])

    const onSubmit = (d) => {
        console.log(d, 'form valdation');
        console.log(dropDownSelection);
    }

    const handleSelect = selectedValue => {
        setDropDownSelection(selectedValue)
    }


    return (
        <Fragment>
            {/* <Breadcrumb parent="Forms" title="Default Forms"/> */}

            <Form className="needs-validation" noValidate="" onSubmit={handleSubmit(onSubmit)}>

                <FormGroup>
                    <Input className="form-control" name="clientName" type="text" placeholder="Client Name" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.clientName?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="scope" type="text" placeholder="Scope" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.scope?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="material" type="text" placeholder="Material" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.material?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input className="form-control" name="plan" type="text" placeholder="Plan" innerRef={register({ required: true })} />
                    <span className='text-danger'>{errors?.plan?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Input defaultValue={options[0].value} type='select' style={{ height: '35px', borderRadius: 4, cursor: 'pointer' }} onChange={(e) => handleSelect(e.target.value)} >
                        {
                            options.map((v, i) => {
                                return <option key={i}>{v.value}</option>
                            })
                        }
                    </Input>
                    <span className='text-danger'>{errors?.status?.message}</span>
                </FormGroup>
                <FormGroup>
                    <Button type='submit' color="primary" className="mr-1" >{Submit}</Button>
                    <Button onClick={() => props.setOpenDrawer(false)} color="secondary">{Cancel}</Button>
                </FormGroup>
            </Form>

        </Fragment>
    );
};

export default WorkOrdersForm;