import React, { Fragment } from 'react';
import Loader from "../layout/loader";
import Header from '../layout/header'
import Sidebar from '../layout/sidebar'
import Footer from '../layout/footer'
import ThemeCustomize from "../layout/theme-customizer";
import { ToastContainer } from 'react-toastify'
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';


const App = ({ children, }) => {
  const { pathname } = useLocation();
  const { userData } = useSelector(state => state.auth);

  if (!["/", "/signin"].includes(pathname) && Object.keys(userData).length > 0) {

    return (
      <Fragment>
        <Loader />
        <div className="page-wrapper compact-wrapper" id="pageWrapper">
          <Header />
          <div className="page-body-wrapper sidebar-icon">
            <Sidebar />
            <div className="page-body">
              {children}
            </div>
            <Footer />
            <ThemeCustomize />
          </div>
        </div>
        <ToastContainer />
      </Fragment>
    );
  }
  else {
    return (
      <Fragment>
        <Loader />
         <div className="page-wrapper compact-wrapper" id="pageWrapper">
             <div className="page-body-wrapper sidebar-icon sidebar-wrapper">
                 <div className="page-body page-header" style={{marginLeft:"0px", width:'100%'}}>
                     {children}
                 </div>
                 <ThemeCustomize/>
             </div>
         </div>
        <ToastContainer />
      </Fragment>
    );

  }

}
export default App;