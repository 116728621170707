import React from "react";
import ReactDOM from "react-dom";
import { Input } from 'reactstrap'
import { DrawingManager, Marker, Polygon, Polyline } from "@react-google-maps/api";
import { Row, Col, Card, CardBody } from "reactstrap";
import { GoogleMap } from "@react-google-maps/api";
import { connect } from "react-redux";


import { setLocation } from "../../../../redux/actions/campaigns";
import { useDispatch } from "react-redux";
import { useEffect } from "react";
import { themeConfig } from "../../../../configs/themeConfig";

const { compose, withProps, lifecycle } = require("recompose");
const { withScriptjs } = require("react-google-maps")
const {
    StandaloneSearchBox
} = require("react-google-maps/lib/components/places/StandaloneSearchBox");

// const mapStateToProps = state => ({
//     campaign: state.Campaign,
// });


const { LATITUDE, LONGITUDE } = themeConfig.app.MAP_INITIALS
// const mapDispatchToProps = dispatch => ({
//     setLocationFunc: loc => dispatch(setLocation(loc))
// })

const GoogleMapSearch = compose(

    // connect(null, mapDispatchToProps),
    withProps({
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />
    }),
    lifecycle({
        componentWillMount() {
            const refs = {};
            this.setState({
                location: this.props?.coords ? JSON.parse(this.props.coords) : { lat: LATITUDE, lng: LONGITUDE },
                prevShape: false,
                onSearchBoxMounted: ref => {
                    refs.searchBox = ref;
                },

                setLocation: (shape) => {
                    const { lat, lng } = shape.position
                    this.state.prevShape && this.state.prevShape.setMap(null);
                    this.setState({ prevShape: shape, location: { lng: lng(), lat: lat() } });
                    localStorage.setItem('coords', JSON.stringify({ lat: lat(), lng: lng() }))
                    // setLocationFunc({ lat: lat(), lng: lng() })
                },
                onPlacesChanged: () => {
                    const location = refs.searchBox.getPlaces()[0].geometry.location;
                    this.setState({ location: { lng: location.lng(), lat: location.lat() } })
                    localStorage.setItem('coords', JSON.stringify({ lat: location.lat(), lng: location.lng() }))
                },

            });
        }
    }),
)((props) => (
    <div data-standalone-searchbox="">
        {console.log('props', props)}
        <label><b>Site Location</b></label>
        <StandaloneSearchBox
            ref={props.onSearchBoxMounted}
            bounds={props.bounds}
            onPlacesChanged={props.onPlacesChanged}
        >
            <Input
                required={props?.coords ? false : true}
                className='form-control'
                type="text"
                placeholder="Search Site"
            />
        </StandaloneSearchBox>
        <Row className='mt-5'>
            <Col sm="12" >
                <GoogleMap
                    id="drawing-manager-example"
                    mapContainerStyle={{
                        width: 'auto',
                        height: 250
                    }}
                    zoom={10}
                    center={props.location}
                >
                    <DrawingManager
                        onMarkerComplete={marker => {
                            props.setLocation(marker)
                            props.onChnageAddress(marker)
                        }}
                        options={{
                            drawingControl: false,
                            drawingMode: "marker",
                        }}
                    />
                    {props.location && <Marker position={props.location}></Marker>}
                </GoogleMap>
            </Col>
        </Row>
    </div>
));
export default GoogleMapSearch;

