const initialState = {
    contactList: []
}

const contactReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'CONTACTS_LIST':
            return {...state, contactList: action.payload}
        default:
            return state
    }
}

export default contactReducer
