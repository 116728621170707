import React, { Fragment, useState, useEffect } from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import App from './components/app';
import * as serviceWorker from './serviceWorker';
import { Provider, useSelector, useDispatch } from 'react-redux';
import { store, persistor } from './store'
import { BrowserRouter, Switch, Route, Redirect, useLocation } from 'react-router-dom'
import { CSSTransition, TransitionGroup } from 'react-transition-group'
import { routes } from './route';
import ConfigDB from './data/customizer/config'
import { classes } from './data/layouts';
import { PersistGate } from 'redux-persist/integration/react';


const Root = (props) => {

  const [anim, setAnim] = useState("");
  const [currentPath, setCurrentPath] = useState();
  const location = useLocation();

  const animation = localStorage.getItem("animation") || ConfigDB.data.router_animation || 'fade'
  const abortController = new AbortController();
  const defaultLayoutObj = classes.find(item => Object.values(item).pop(1) === 'compact-wrapper');
  const layout = localStorage.getItem('layout') || Object.keys(defaultLayoutObj).pop();
  const auth = useSelector(state => state.auth)
  const { userData } = auth


  useEffect(() => {
    setCurrentPath(location.pathname)
    setAnim(animation)
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    }
  }, [userData]);

  return (
    <Fragment>
      <Switch>
        <App>

          {Object.keys(userData).length < 1 && <Route path={`${process.env.PUBLIC_URL}/`} render={() => {
            return (<Redirect to={`${process.env.PUBLIC_URL}/signin`} />)
          }} />}

          {Object.keys(userData).length > 0 && <Route exact path={`${process.env.PUBLIC_URL}/signin`} render={() => {
            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
          }} />}

          {Object.keys(userData).length > 0 && <Route exact path={`${process.env.PUBLIC_URL}/`} render={() => {
            return (<Redirect to={`${process.env.PUBLIC_URL}/dashboard`} />)
          }} />}

          <TransitionGroup>
            {routes.map(({ path, Component,exact }) => (
              <Route key={path} exact={exact} path={`${process.env.PUBLIC_URL}${path}`}>
                {({ match }) => (
                  <CSSTransition
                    in={match != null}
                    timeout={100}
                    classNames={anim}
                    unmountOnExit
                  >
                    <div><Component /></div>
                  </CSSTransition>
                )}
              </Route>
            ))}
          </TransitionGroup>
        </App>
      </Switch>

    </Fragment>
  )
}
ReactDOM.render(
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <BrowserRouter basename={`/`}>
        <Root />
      </BrowserRouter>
    </PersistGate>
  </Provider>,
  document.getElementById('root')
);

serviceWorker.unregister();
