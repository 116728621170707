import React from 'react'
import DataTableComponent from '../../components/tables/dataTable';


const DataTable = (props) => {

    return (
        <DataTableComponent
            additionalBtn={props.additionalBtn}
            fetchApi={props.fetchApi}
            deleteApi={props.deleteApi}
            setIsEdit={props.setIsEdit}
            accountsName={props.accountsName}
            dropDown={props.dropDown}
            drawerComp={props.drawerComp}
            setOpenDrawer={props.setOpenDrawer}
            tableColumnsName={props.tableColumnsName}
            api={props.api}
            screenTitle={props.screenTitle}
        />
    )
}

export default DataTable;