import React, { Fragment, useState, useEffect } from 'react';
import Breadcrumb from '../../../../layout/breadcrumb'
import { Container, Row, Col } from 'reactstrap'
import { Edit, Trash2 } from 'react-feather';
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify'
import SweetAlert from 'sweetalert2'
import { Drawer } from 'rsuite';
import "react-toastify/dist/ReactToastify.css";
import './index.css'
import { deleteById, fetchById, fetchDBTable } from '../../../../redux/actions/actions';
import { setFilters, } from '../../../../redux/actions/common';
import CampaignsForm from '../sidebar/form';
import moment from 'moment';
import Header from "../../../../components/header/header";
import { setCampaigns } from "../../../../redux/actions/campaigns";
import Spinner from "../../../../components/spinner/spinner";

const Campaigns = (props) => {

    const history = useHistory()
    const dispatch = useDispatch();
    const common = useSelector(state => state.Common)
    const campaign = useSelector(state => state.Campaign)

    const [openDrawer, setOpenDrawer] = useState(false)
    const [fetchedRecord, setFetchRecord] = useState({});
    const [campaignLoading, setCampaignLoading] = useState(false);

    const getCampaigns = () => {
        dispatch(fetchDBTable({ ...common.filters }, 'campaigns'))
            .then(({ data }) => {
                dispatch(setCampaigns(data.response))
                setCampaignLoading(false)
            }).catch(err => {
            console.log(err)
        }).finally(() => {
        })
    }

    const fetchAndSetState = () => {
        getCampaigns();
    }

    useEffect(() => {
        setCampaignLoading(true)
        fetchAndSetState();
    }, [])


    const showAlert = (id) => {
        SweetAlert.fire({
            title: "Are you sure you want to do this?",
            cancelButtonText: "Cancel",
            confirmButtonText: "Confirm",
            reverseButtons: true,
            showCancelButton: true,
            cancelButtonColor: '#fbbb1a',
            confirmButtonColor: "black",
        }).then(result => {
            if (result.isConfirmed === true) {
                deleteById({ id }, 'campaigns')
                    .then(res => {
                    }).finally(() => {
                    toast.success("Record Successfully Deleted!", {
                        position: toast.POSITION.TOP_CENTER,
                    });
                    setCampaignLoading(false)
                    getCampaigns()
                })
            }
        })
    }

    const deleteAction = (id) => {
        showAlert(id)
    }

    const editAction = (id) => {
        fetchById({ id }, 'campaigns/get')
            .then(res => {
                setFetchRecord(res.data.response[0])
                setOpenDrawer(true)
            })
    }

    const navigationAction = (item) => {
        dispatch(setFilters({ ...common.filters, id: item.id }))
        history.push({ pathname: '/campaigns/campaign-details/' + item.id, state: item })

    }

    const shortName = (str) =>{
        let acronym = str.split(/\s/).reduce((response, word) => response += word.slice(0, 2), '')
        return acronym.substring(0, 2).toUpperCase();
    }

    return (

        <Fragment>
            <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)} size={'sm'} style={{ width: '25%' }}>
                <Drawer.Header><Drawer.Title>{Object.keys(fetchedRecord).length > 0 ? 'Edit Campaign' : 'Create Campaign'}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body>
                    <CampaignsForm fetchedRecord={fetchedRecord} setOpenDrawer={setOpenDrawer}
                                   getCampaigns={getCampaigns} />
                </Drawer.Body>
            </Drawer>

            <Breadcrumb parent="Campaigns" title={<h3 style={{ marginLeft: '20px' }}>Campaigns</h3>} />
            <Container fluid={true}>
                <Row>
                    <Col sm="12">

                        <Header api={'campaigns'} setFetchRecord={setFetchRecord} setOpenDrawer={setOpenDrawer} setCampaignLoading={setCampaignLoading} />

                        {campaignLoading ? <div style={{ height: '500px', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                <Spinner />
                            </div>
                            : <Row>
                                {
                                    campaign.campaignList.length > 0 && campaign.campaignList.map((item, i) =>

                                        <Col sm="3" className='mt-3 mb-3' key={i} onClick={() => navigationAction(item)}>
                                            <div className="project-box" style={{ cursor: 'pointer' }}>

                                                <div style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'flex-end',
                                                    alignItems: 'center',
                                                    marginBottom: '10px'
                                                }}>
                                                    <div className={`badge ${item.is_active === 0 ? 'badge-danger' : 'badge-success'}`}>{item.is_active === 0 ? 'Inactive' : 'Active'}</div>
                                                    <Edit onClick={e => {editAction(item.id);e.stopPropagation()}} size={14} style={{ marginRight: '5px' }} />
                                                    <Trash2 onClick={e => {deleteAction(item.id);e.stopPropagation()}} size={15} />
                                                </div>

                                                <h6 style={{ marginBottom: '10px' }}>{item.campaign_name}</h6>
                                                <div className="media">
                                                    <div style={{borderRadius:'50%', padding:'8px', marginRight:'10px', backgroundColor: '#fbbb1a', width: '36px', color: '#fff', textAlign: 'center'}}>
                                                        <b>{shortName(item.first_name)}</b>
                                                    </div>
                                                    <div className="media-body" style={{ lineHeight: 1 }}>
                                                        <p style={{ fontSize: '11px' }} className='mb-0'>Created By
                                                            <b style={{ color: 'black' }}>{item.first_name + ' ' + item.last_name}</b>
                                                        </p>
                                                        <span style={{ fontSize: '11px' }}>Account Name:
                                                            <b style={{ color: 'black' }}>{item.name}</b>
                                                        </span>
                                                    </div>
                                                </div>

                                                <p style={{minHeight: '60px', maxHeight: '60px'}}>
                                                    {item.description.substr(0, 30) + '...'}
                                                </p>

                                                <Row className="details mt-4">
                                                    <Col xs="8"><span>Number of Sites</span></Col>
                                                    <Col xs="4" style={{ color: item.is_active === 0 ? '#dc3545' : '#51bb25' }}>{item.total_sites}</Col>
                                                </Row>
                                                <Row className="details mt-1">
                                                    <Col xs="6"><span>{'Created At'} </span></Col>
                                                    <Col xs="6" style={{ color: item.is_active === 0 ? '#dc3545' : '#51bb25' }}>
                                                        {moment(item.created_at).format('MM-DD-YYYY')}
                                                    </Col>
                                                </Row>

                                            </div>
                                        </Col>
                                    )}
                                {
                                    campaign.campaignList.length < 1 && <div style={{ height: '200px', width: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }} >
                                        <span>There are no records to display</span>
                                    </div>
                                }

                            </Row>}
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );

}

export default Campaigns;

