import axios from "axios";
import { themeConfig } from '../../configs/themeConfig'

const { apiUrl } = themeConfig.app;

export const fetchZones = (path, id) => {
    return dispatch => {
        return axios.post(`${apiUrl}/v1/${path}`, id)
            .then(res => {
                // console.log('Res', res)
                return res
            })
    }
}

export const createZone = (api, postData) => {
    return dispatch => {
        return axios.post(`${apiUrl}`, postData)
            .then(res => {
                return res
            })
    }
}
