import React, { Fragment, useState, useEffect } from 'react'
import DataTable from '../../table';
import { Drawer } from 'rsuite';
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import './index.css'
import { setFetchedRecord, setOpenDrawer } from '../../../../redux/actions/common';
import { themeConfig } from "../../../../configs/themeConfig";
import { fetchById, deleteById, fetchData } from '../../../../redux/actions/actions';
import ContactsForm from '../sidebar/form';
import { useSelector, useDispatch } from 'react-redux';
import { setFilters } from '../../../../redux/actions/common';

const Contacts = () => {
    const [accountsName, setAccountsName] = useState([]);

    const common = useSelector(state => state.Common)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(setFilters({ endDate: "", keyword: "", page: 1, perPage: 10, startDate: "", id: null }))
        fetchData('accounts/list')('')
            .then(res => {
                setAccountsName(res.data.response)
            })
    }, [])


    const active = () => {
        return (
            <div style={{ backgroundColor: '#38b000', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Active</span>
            </div>
        )
    }
    const inactive = () => {
        return (
            <div style={{ backgroundColor: '#e63946', borderRadius: '12px', padding:'3px 10px' }} >
                <span style={{ color: 'white' }}>Inactive</span>
            </div>
        )
    }

    const handleClose = () => {
        dispatch(setOpenDrawer(false))
        dispatch(setFetchedRecord({}))
    }

    const drawerComp = (props) => {
        return (
            <Drawer open={common.openDrawer} onClose={handleClose} size={'sm'} style={{ width: '25%' }}>
                <Drawer.Header >
                    <Drawer.Title>{Object.keys(common.fetchedRecord).length > 0 ? 'Edit Contact' : 'Create Contact'}</Drawer.Title>
                </Drawer.Header>
                <Drawer.Body >
                    <ContactsForm
                        getUsers={props.getUsers}
                    />
                </Drawer.Body>
            </Drawer>
        )
    }

    return (
        <Fragment>
            <DataTable
                api={'contacts'}
                fetchApi={'contacts/get'}
                deleteApi={'contacts'}
                accountsName={accountsName}
                dropDown={true}
                drawerComp={drawerComp}
                setOpenDrawer={setOpenDrawer}
                screenTitle={'Contacts'}
                tableColumnsName={[
                    { name: 'Id', selector: 'id' },
                    { name: 'First', selector: 'first_name' },
                    { name: 'Last', selector: 'last_name' },
                    { name: 'Email', selector: 'email' },
                    { name: 'Phone', selector: 'phone' },
                    { name: 'Status ', selector: row => row.is_active === 1 ? active() : inactive() },
                    { name: 'Created At ', selector: row => moment(row.created_at).format(themeConfig.app.dateFormat) },
                ]}
            />
        </Fragment>
    )
}

export default Contacts;